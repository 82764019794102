export default [
    {
        path: '/prohibiteditems',
        name: 'prohibitedItems',
        component: () => import('@/yicpages/ProhibitedItems/ProhibitedItems.vue'),
        meta: {
            layout: 'full',
        },
    },
    
]