import Vue from "vue";
import Vuex from "vuex";
import feathersClient from "../../../feathers-client";

Vue.use(Vuex);

export default{
    namespaced: true,
    state:{
        countryCode: [],
        enabledCountries: []
    },
    mutations:{
       SET_ENABLED_COUNTRIES(state, val){
        state.enabledCountries = val
       }
    },
    actions:{
        getEnabledCountries: async({commit, state}) => {
            const res = await feathersClient.service('/api/v1/enabledCountries').find({})
            state.countryCode = res.data.map(country => {
                return country.countryCode
            })
            commit('SET_ENABLED_COUNTRIES', res.data)
        },
        addCountry: async({commit, state}, payload) => {
            await feathersClient.service('api/v1/enabledCountries').create({
                countryName: payload.countryName,
                countryCode: payload.countryCode
            })
        },
        deleteCountry: async({commit, state}, id) => {
            await feathersClient.service('api/v1/enabledCountries').remove(id)
        }
    },
};
    