import feathersClient from '../../../feathers-client'
export default {
  namespaced: true,
  state: {
    Details: [],
    gstCharges: 0,
    yicCharges: 0,
    orderId: '',
    keyId: '',
    fPrice: 0,
    paymentDone: false,
    showLoader: false,
    additionalCharges: [],
    finalPriceInRupees: 0,
    title: [],
    allAdditionalCharges: [],
    paymentGatewayOption: '',
    src: '',
    ccAvenueResponse: '',
    ccAvenuePaymentMode: '',
    finalWeight: 0,
    totalPrice: 0,
    yourCartItems: []
  },
  mutations: {
    SET_DETAILS(state, val) {
      state.Details=val;
    },
	RESET_DETAILS(state){
		state.Details=[],
    state.title = [],
    state.additionalCharges = [],
    state.allAdditionalCharges = []
	},
  UPDATE_DETAILS(state, val){
    state.Details.yourCartItems = val
  },
  SET_CHARGES(state, val) {
    state.yicCharges = parseInt(val.consolidationFees)
  },
  SET_ADDITIONAL_CHARGES(state, val) {
    state.additionalCharges.push(val)
  },
  SET_ORDER_ID(state, val) {
    state.orderId = val
  },
  SET_KEY_ID(state, val) {
    state.keyId = val
  },
  SET_FINAL_PRICE(state, val) {
    state.fPrice = val
  },
  SET_PAYMENT_DONE(state, val) {
    state.paymentDone = val
  },
  SHOW_LOADER(state, val) {
    state.showLoader = val
  },
  SET_FINAL_PRICE_IN_RUPEES(state, val) {
    state.finalPriceInRupees = val
  },
  SET_TITLE(state, val) {
    state.title.push(val)
  },
  SET_ALL_ADDITIONAL_CHARGES(state, val) {
    state.allAdditionalCharges = val
  },
  SET_PAYMENT_GATEWAY_OPTION(state, val) {
    state.paymentGatewayOption = val
  },
  SET_CCAVENUE_REQUEST_URL(state, val) {
    state.src = val
  },
  SET_CCAVENUE_RESPONSE_DATA(state, val) {
    state.ccAvenueResponse = val
  },
  SET_PAYMENT_MODE(state, val) {
    state.ccAvenuePaymentMode = val
  },
  SET_FINAL_WEIGHT(state, val) {
    state.finalWeight = val
  },
},
  actions: {
    getShippedDetails: async ({ commit,state }, payload) => {
		commit('RESET_DETAILS')
      const result = await feathersClient.service('/api/v1/shippingpackage').find({
        query: {
          $limit: 1000,
          id: payload.id
        }
      })
      console.log("result from shippingpackage", result)
      const res = await feathersClient.service('/api/v1/shippingpartners').find({
        query: {
          id: result.data[0].selectedShippingPartnerId
        }
      })
      result.data[0]['shippingPartnerName'] = res.data[0].shippingpartners
    commit('SET_DETAILS', result.data)
    },
    updateCharges: async ({commit, state}, payload) => {
      const result = await feathersClient.service('api/v1/adminsystemconfigs').find({
      })
      commit("SET_CHARGES", result.data[0])
      const result2 = await feathersClient.service('/api/v1/additionalcharges').find({
        query: {
          shippingpackageId: payload.shippingPackageDetails[0].id
        }
      })
      if(result2.data.length>0){
        for(let i=0;i<result2.data.length;i++) {
          await commit('SET_ADDITIONAL_CHARGES', result2.data[i].amount)
          await commit('SET_TITLE',result2.data[i].title)
          await commit('SET_ALL_ADDITIONAL_CHARGES', result2.data)
        }
      }

      if(state.additionalCharges.length>0) {
        let sum=0;
        for(let i=0;i<state.additionalCharges.length; i++) {
          sum=sum+parseInt(state.additionalCharges[i]);
        }
      commit('SET_FINAL_PRICE_IN_RUPEES',(parseInt(payload.shippingPrice) + state.yicCharges + sum + payload.cartTotal))
      }
      else {
      commit('SET_FINAL_PRICE_IN_RUPEES',(parseInt(payload.shippingPrice) + state.yicCharges + payload.cartTotal ) )
      }
    },

    updateFinalPrice: async ({commit,state}, payload) => {
      const result = await feathersClient.service('api/v1/adminsystemconfigs').find({
      })
      console.log('result.data[0]',result.data[0])
      commit("SET_CHARGES", result.data[0])
      const result2 = await feathersClient.service('/api/v1/additionalcharges').find({
        query: {
          shippingpackageId: payload.shippingPackageDetails.id
        }
      })
      if(result2.data.length>0){
        for(let i=0;i<result2.data.length;i++) {
          await commit('SET_ADDITIONAL_CHARGES', result2.data[i].amount)
          await commit('SET_TITLE',result2.data[i].title)
          await commit('SET_ALL_ADDITIONAL_CHARGES', result2.data)
        }
      }
      // if(state.additionalCharges.length>0) {
      if(result2.data.length>0) {
        let sum=0;
        for(let i=0;i<result2.data.length; i++) {
          // sum=sum+parseInt(state.additionalCharges[i]);
          sum=sum+parseInt(result2.data[i].amount);
        }
        const response = await feathersClient.service('/api/v1/shippingpackage').patch(payload.shippingPackageDetails.id, {
          finalPrice: (parseInt(payload.shippingPackageDetails.shippingPrice) + state.yicCharges + sum + payload.cartItemsPrice) * 100.0,
          // shippingPrice: parseInt(payload.shippingPrice),
          // weight: parseInt(payload.finalWeight),
          // cartTotal: payload.cartTotal,
          // numberOfItems: payload.numberOfItems
        })
        if(response.orderId) {
          commit('SET_FINAL_PRICE', response.finalPrice)
          // commit('SET_FINAL_PRICE_IN_RUPEES', response.finalPrice*100)
          commit('SET_ORDER_ID', response.orderId)
          commit("SET_KEY_ID", response.keyId)
        }
        const paymentOption = await feathersClient.service('api/v1/paymentgatewayselection').find({})
        commit('SET_PAYMENT_GATEWAY_OPTION', paymentOption.paymentgatewayOption)
        commit('SET_PAYMENT_MODE', paymentOption.paymentMode)
      }
      else {
        const response = await feathersClient.service('/api/v1/shippingpackage').patch(payload.shippingPackageDetails.id, {
          finalPrice: (parseInt(payload.shippingPrice) + state.yicCharges + payload.cartItemsPrice) * 100.0,
          // shippingPrice: parseInt(payload.shippingPrice),
          // weight: parseInt(payload.finalWeight),
          // cartTotal: payload.cartTotal,
          // numberOfItems: payload.numberOfItems
        })
        if(response.orderId) {
          commit('SET_FINAL_PRICE', response.finalPrice)
          // commit('SET_FINAL_PRICE_IN_RUPEES', response.finalPrice*100)
          commit('SET_ORDER_ID', response.orderId)
          commit("SET_KEY_ID", response.keyId)
        }
        const paymentOption = await feathersClient.service('api/v1/paymentgatewayselection').find({})
        commit('SET_PAYMENT_GATEWAY_OPTION', paymentOption.paymentgatewayOption)
        commit('SET_PAYMENT_MODE', paymentOption.paymentMode)
      }
    },
    createRazorpayPaymentsSuccess: async ({commit, state}, payload) => {
      const res = await feathersClient.service('api/v1/razorpaypaymentsuccess').create({
        orderId: payload.orderId,
        paymentId: payload.paymentId,
        signature: payload.signature,
        shippingpackageId: state.Details[0].id
      })
      if(res.paymentStatus == 'Done') {
        commit('SET_PAYMENT_DONE', true)
      }
      await feathersClient.service('/api/v1/shippingpackage').patch(state.Details[0].id,{
          paymentId: payload.paymentId
      }, {})
    },
    getCCAvenuerequestUrlDetails: async({commit, state}) => {
      const res = await feathersClient.service('api/v1/ccAvenueRequest').find({
      })
      commit('SET_CCAVENUE_REQUEST_URL', res)
    },
    createCCAvenuePayment: async({commit, state}, payload) => {
      const res = await feathersClient.service('api/v1/ccavenuepayment').create({
        amount: payload.orderParams.amount,
        shippingPackageId: payload.orderParams.shippingPackageId
      })
      commit('SET_CCAVENUE_RESPONSE_DATA', res)
    },
    getPaymentStatus: async({commit, state}, payload) => {
      const res = await feathersClient.service('api/v1/shippingpackage').find({
        query: {
          $limit: 1000,
          id: payload.id
        }
      })
      if(res.data[0].paymentStatus == 'Done')
        commit('SET_PAYMENT_DONE', true)
      else{
        commit('SET_PAYMENT_DONE', false)
      }
      return res.data[0].paymentStatus;
    },
    updatePaymentStatus: async({commit, state}, payload) => {
      const res = await feathersClient.service('/api/v1/shippingpackage').patch(payload.id, {
        paymentStatus: 'Ready For Payment'
      })
    },
    setPaymentStatus: async ({ commit, state }, payload) => {
      const paymentOption = await feathersClient.service('api/v1/paymentgatewayselection').find({})
      commit('SET_PAYMENT_GATEWAY_OPTION', paymentOption.paymentgatewayOption)
      commit('SET_PAYMENT_MODE', paymentOption.paymentMode)
    },
    updateItemsInUserBinItems: async({commit, state}, payload) => {
      const res = await feathersClient.service('/api/v1/shippingpackage').find({
        query:{
          id: payload.shippingPackageId
        }
      })
      if(res.data[0].paymentStatus != 'Done'){
        return;
      }
      const purchasedItemPromised = payload.yourCartItems.length>0 && payload.yourCartItems.map(async(item) => {
        await feathersClient.service('/api/v1/purchaseditems').create({
          variantId: item.variantId,
          quantity: item.quantity,
          price: (item.variant[0].priceOffer || item.variant[0].priceOriginal)
        });

      // finding category of product
      const productCategory = await feathersClient.service('/api/v1/productcategories').find({
          query: {
              id: item.product[0].productcategoryId,
          }
      });

      const { variantId } = item;
      const t = await feathersClient.service('/api/v1/variants').find({
          query: {
              id: variantId
          },
      })
      const productId = t.data[0].productId;
      const l = await feathersClient.service('/api/v1/products').find({
          query: {
              id: productId
          },
      });
      const vendorId = l.data[0].vendorId;

      //updating inventory quantity for the variant
      await feathersClient.service('/api/v1/variants').patch(item.variantId, {
          inventoryQuantity: (t.data[0].inventoryQuantity - item.quantity)
      })
        const result = await feathersClient.service('api/v1/userbinitems').create({
          itemName: item.product[0].name,
          itemCategory: productCategory.data[0].name,
          whenArrived: Date.now(),
          itemSourceType: 'store',
          itemSource: 'store',
          quantity: item.quantity,
          value: (item.variant[0].priceOffer || item.variant[0].priceOriginal) * item.quantity,
          description: item.product[0].description,
          consolidate: false,
          fulfilmentStatus: 'Pending',
          packageStatus: 'dummy added',
          variantId: item.variantId,
          shippingpackageId: payload.shippingPackageId,
          vendorId
        })
        for (let i = 0; i < 3; i++) {
          if (item.product[0].productImages[i]) {
              const res = await feathersClient.service('/api/v1/userbinitemspicture').create({
                  url: item.product[0].productImages[i],
                  userbinitemId: result.id,
              })
          }
        }
        await feathersClient.service('/api/v1/shoppingcartitems').remove(item.id)
      })
      await Promise.all(purchasedItemPromised)
    },

    updateShippingPackage: async({commit, state}, payload) => {
      await feathersClient.service('/api/v1/shippingpackage').patch(payload.id, {
        cartTotal: payload.cartTotal,
        numberOfItems: payload.numberOfItems,
        weight: payload.weight,
        finalPrice: payload.finalPrice,
        shippingPrice: payload.shippingPrice
      })
      commit('SET_CART_TOTAL', payload.cartTotal)
  }
  },
}

// { shippingPackageDetails: this.getDetails[0], 
//   cartItemsPrice: this.totalYourCartItemPrice.get(this.$route.params.id), 
//   finalWeight: this.itemsWeight, 
//   shippingPrice: this.shippingPartnersDetails.has(this.$route.params.id) && this.shippingPartnersDetails.get(this.$route.params.id).length>0 && this.shippingPartnersDetails.get(this.$route.params.id)[0].price, 
//   cartTotal: this.getCartTotal, 
//   numberOfItems: this.getDetails[0].numberOfItems + this.yourCartItems.get(this.$route.params.id).length
//  }