import feathersClient from '../../../feathers-client'
export default {
  namespaced: true,
  state: {
    navbar: 'homepage',
    sidebar: '',
    myOrdersNavbar: ''
  },
  mutations: {
   SET_NAVBAR(state, val) {
    state.navbar = val
   },
   SET_SIDEBAR(state, val) {
    state.sidebar = val
   },
   SET_MY_ORDERS_NAVBAR(state, val) {
    state.myOrdersNavbar = val
   }
  },
  actions: {}
}
