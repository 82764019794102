import Vue from "vue";
import Vuex from "vuex";

import feathersClient from "../../../feathers-client";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    warehouseAddress: null,
    alreadyVerified: false,
  },
  mutations: {
    setWarehouseAddress: function (state, val) {
      state.warehouseAddress = val;
    },
    setAlreadyVerified: function (state) {
      state.alreadyVerified = true;
    },
  },
  actions: {
    verifyYourEmail: async ({ commit }, payload) => {
      const verify = await feathersClient
        .service("/api/v1/authmanagement")
        .create({
          action: "verifyEmail",
          value: payload.token,
          notifierOptions: {},
        });
      console.log("Verify -> ", verify);
      if (verify.isAlreadyVerified) {
        commit("setAlreadyVerified");
      } else {
        if (verify.suiteAddress.data.length>0) {
          const address = verify.suiteAddress.data[0].warehouseAddress;
          commit("setWarehouseAddress", address);
        }

      }
    },
  },
};
