export default [
  {
    path: '/adminDashboard',
    name: 'adminDashboard',
    component: () => import("@/yicpages/admin/dashboard.vue"),
    meta: {
      layout: "full",
      authRequired: true,
      adminPage: true,
    },
    children: [
      {
        path: '/categories',
        name: 'categories',
        component: () => import('@/yicpages/admin/categories/categories-list/categoriesList.vue'),
        meta: {
          layout: 'full',
          authRequired: true,
          adminPage: true,
        },
      },
      {
        path: '/merchants',
        name: 'merchants',
        component: () => import('@/yicpages/admin/merchants/merchants-list/merchantsList.vue'),
        meta: {
          layout: 'full',
          authRequired: true,
          adminPage: true,
        },
      },
      {
        path: '/systemconfig',
        name: 'systemconfig',
        component: () => import('@/yicpages/admin/systemconfig/systemconfig-list/systemconfigList.vue'),
        meta: {
          layout: 'full',
          authRequired: true,
          adminPage: true,
        },
      },
      {
        path: '/shippingPartners',
        name: 'shippingPartners',
        component: () => import('@/yicpages/admin/shippingpartners/shippingpartnersList/shippingPartners.vue'),
        meta: {
          layout: 'full',
          authRequired: true,
          adminPage: true,
        },
      },
      {
        path: '/opsUsers',
        name: 'opsUsers',
        component: () => import('@/yicpages/admin/opsUsers/opsUser-list/opsUsersList.vue'),
        meta: {
          layout: 'full',
          authRequired: true,
          adminPage: true,
        },
      },
      {
        path: '/customerDetails',
        name: 'customerDetails',
        component: () => import('@/yicpages/admin/customerDetails/customerDetails.vue'),
        meta: {
          layout: 'full',
          authRequired: true,
          adminPage: true,
        },
      },
      {
        path: '/addAdditionalCharges',
        name: 'addAdditionalCharges',
        component: () => import('@/yicpages/admin/additionalCharges/additionalCharges-list/additionalchargesList.vue'),
        meta: {
          layout: 'full',
          authRequired: true,
          adminPage: true,
        },
      },
      {
        path: '/createOrders',
        name: 'createOrders',
        component: () => import('@/yicpages/admin/createOrder/createOrder'),
        meta: {
          layout: 'full',
          authRequired: true,
          adminPage: true,
        },
      },
      {
        path: '/addTestimonials',
        name: 'addTestimonials',
        component: () => import('@/yicpages/admin/homepageTestimonials/addHomepageTestimonials'),
        meta: {
          layout: 'full',
          authRequired: true,
          adminPage: true,
        },
      },
      {
        path: '/addStore',
        name: 'addStore',
        component: () => import('@/yicpages/admin/adminStore/addStore.vue'),
        meta: {
          layout: 'full',
          authRequired: true,
          adminPage: true,
        },
      },
      {
        path: '/addCarousel',
        name: 'addCarousel',
        component: () => import('@/yicpages/admin/homepageCarousel/addHomepageCarousel.vue'),
        meta: {
          layout: 'full',
          authRequired: true,
          adminPage: true,
        },
      },
      {
        path: '/store-inventory',
        name: 'store-inventory',
        component: () => import('@/yicpages/admin/adminStore/Inventory.vue'),
        meta: {
          layout: 'full',
          authRequired: true,
          adminPage: true,
        },
      },
      {
        path: '/suit-items',
        name: 'suit-items',
        component: () => import('@/yicpages/admin/suitItems/suitItems.vue'),
        meta: {
          layout: 'full',
          authRequired: true,
          adminPage: true,
        },
      },
      {
        path: '/enabledCountries',
        name: 'enabledCountries',
        component: () => import('@/yicpages/admin/EnabledCountries.vue'),
        meta: {
          layout: 'full',
          authRequired: true,
          adminPage: true,
        },
      },
    ],

  },

  {
    path: '/adminConsolidationStatus',
    name: 'adminConsolidationStatus',
    component: () => import('@/yicpages/admin/consolidationstatus/status.vue'),
    meta: {
      layout: 'full',
      authRequired: true,
      adminPage: true,
    },
  },

  {
    path: '/toBeConsolidated',
    name: 'toBeConsolidated',
    component: () => import('@/yicpages/admin/consolidationstatus/tobeconsolidate.vue'),
    meta: {
      layout: 'full',
      authRequired: true,
      adminPage: true,
    },
  },

  {
    path: '/inProgress',
    name: 'inProgress',
    component: () => import('@/yicpages/admin/consolidationstatus/inprogress.vue'),
    meta: {
      layout: 'full',
      authRequired: true,
      adminPage: true,
    },
  },

  {
    path: '/awaitingShip',
    name: 'awaitingShip',
    component: () => import('@/yicpages/admin/consolidationstatus/awaiting.vue'),
    meta: {
      layout: 'full',
      authRequired: true,
      adminPage: true,
    },
  },

  {
    path: '/shippingDone',
    name: 'shippingDone',
    component: () => import('@/yicpages/admin/consolidationstatus/shippingdone.vue'),
    meta: {
      layout: 'full',
      authRequired: true,
      adminPage: true,
    },
  },

  {
    path: '/finalConsolidate',
    name: 'finalConsolidate',
    component: () => import('@/yicpages/admin/consolidationstatus/finalpage.vue'),
    meta: {
      layout: 'full',
      authRequired: true,
      adminPage: true,
    },
  },
  




]
