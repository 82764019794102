import feathersClient from '../../../feathers-client'
import router from '../../../router'
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";



export default ({
	namespaced: true,
	plugins: [
		createPersistedState({
			storage: window.sessionStorage,
		}),
	],
	state: {
		authPayload: {
			email: '',
			password: ''
		},
		isLoading: false,
		error: null,
		isAuthenticated: false,
		currUser: {
			firstName: '',
			lastName: '',
			role: '',
			id: null
		}
	},
	getters: {
		isAuthenticated: state => state.isAuthenticated,
		userName: state => state.currUser.firstName,
		role: state => state.currUser.role,
		id: state => state.currUsser.id
	},
	mutations: {
		SET_AUTHPAYLOAD(state, val) {
			state.authPayload.email = val.email
			state.authPayload.password = val.password
		},
		SET_LOADING(state, isLoading) {
			state.loading = isLoading
			state.error = null
		},
		SET_ERROR(state, val) {
			state.loading = false
			state.error = val
		},
		SET_ISAUTHENTICATED(state, val) {
			state.isAuthenticated = val
		},
		SET_CURRUSER(state, val) {
			state.currUser.firstName = val.firstName
			state.currUser.lastName = val.lastName
			state.currUser.role = val.role
			state.currUser.id = val.id
		},
		RESET_CURR_USER(state) {
			state.authPayload.email = ""
			state.authPayload.password = ''
			state.isAuthenticated = false
			state.isLoading = false
			state.currUser.firstName = ''
			state.currUser.lastname = ''
			state.currUser.role = ''

		}

	},
	actions: {
		loginUser: async ({ commit, state }) => {
			commit('SET_LOADING', true)
			try {
				const res = await feathersClient.authenticate({
					strategy: 'local',
					email: state.authPayload.email,
					password: state.authPayload.password
				});
				// if (res.users.role != 'OPERATOR') {
					commit('SET_ISAUTHENTICATED', true)
					commit('SET_LOADING', false)
					localStorage.setItem("feathers-jwt", res.accessToken)
					commit('SET_CURRUSER', res.users)
					//====== once user logged in, transfer cart items from local to userId =====/
					if (res) {
						const userId = res.users.id;
						const anonymousUserId = localStorage.getItem('anonymousUserId');
						if (anonymousUserId) {
							const items = await feathersClient.service('/api/v1/shoppingcartitems').find({
								query: {
									anonymousUserId
								}
							})
							items.data.map(async item => {
								await feathersClient.service('/api/v1/shoppingcartitems').patch(item.id, {
									anonymousUserId: null,
									userId,
								})
								return item;
							})
							localStorage.removeItem('anonymousUserId')
						}
					}
					//================================= end ===================================/


				// }
				// else {
				// 	commit('SET_ERROR', 'Operator users are not allowed')
				// }
			}
			catch (e) {
				console.log("Authentication error", e)
				commit('SET_ERROR', e)
			}


		},
		loginUserWithJwt: async ({ commit, state }) => {
			const token = localStorage.getItem('feathers-jwt')
			// this.tokenAuthentication=token
			// console.log("token : ", token)
			if (token) {
				try {
					const result = await feathersClient.authenticate({
						strategy: 'jwt',
						accessToken: token,

					});
					commit('SET_ISAUTHENTICATED', true)
					commit('SET_LOADING', false)
					commit('SET_TOKEN', token)
					// commit('SET_DETAILS', result.users.firstName)
					commit('SET_CURRUSER', result.users)
					//====== once user logged in, transfer cart items from local to userId =====/
					if (result) {
						const userId = result.users.id;
						const anonymousUserId = localStorage.getItem('anonymousUserId');
						if (anonymousUserId) {
							const items = await feathersClient.service('/api/v1/shoppingcartitems').find({
								query: {
									anonymousUserId
								}
							})
							items.data.map(async item => {
								await feathersClient.service('/api/v1/shoppingcartitems').patch(item.id, {
									anonymousUserId: null,
									userId,
								})
								return item;
							})
							localStorage.removeItem('anonymousUserId')
						}
					}
					//================================= end ===================================/


				}
				catch (e) {
					console.log("Authentication error", e)
					commit('SET_ERROR', e)
				}
			}
		},
		logoutUser: async ({ commit, state }) => {
			await feathersClient.logout()
			commit('RESET_CURR_USER')
		},
		// getUserDetails: async ({commit, state})=> {
		// 	await feathersClient.service('/api/v1/users').create({
		// 		firstName: state.currUser.firstName,

		// 	})
		// }
	}
})
