import feathersClient from "../../../feathers-client";

export default {
  namespaced: true,
  state: {
    addressExists: false,
    uid: null,
    fName: "",
    lName: "",
    addrL1: "",
    addrL2: "",
    aadharFront: "",
    aadharBack: "",
    contact: "",
  },
  getters: {
    // getFName: (state) => {
    //   return state.firstName;
    // },
    // getLName: (state) => {
    //   return state.lastName;
    // },
    // getAddrL1: (state) => {
    //   return state.addrL1;
    // },
    // getAddrL2: (state) => {
    //   return state.addrL2;
    // },
  },
  mutations: {
    setDetails(state, obj) {
      if (obj) {
        console.log("$$ ",obj);
        state.addressExists = true;
        state.uid = obj.id;
        state.fName = obj.firstName;
        state.lName = obj.lastName;
        state.addrL1 = obj.addressLine1;
        state.addrL2 = obj.addressLine2;
        state.aadharFront = obj.aadharFrontUrl;
        state.aadharBack = obj.aadharBackUrl;
        state.contact = obj.phoneNumber;
      }

    },
  },
  actions: {
    async updateSenderDetails({ commit, state }, obj) {
      console.log("state.id -> ", state.uid);
      await feathersClient.service("/api/v1/senderaddresses").patch(state.uid,
        {
          phoneNumber: obj.phoneNumber,
          firstName: obj.firstName,
          lastName: obj.lastName,
          addressLine1: obj.addressLine1,
          addressLine2: obj.addressLine2,
          aadharFrontUrl: obj.aadharFrontUrl,
          aadharBackUrl: obj.aadharBackUrl,
        },
        {}
      );
      console.log("^^^^ ",obj);
      // obj["id"] = state.uid;
      commit('setDetails', obj);
      // getSenderDetails();
      // Call the api to update the sender details
    },

    async createSenderDetails({ commit, state}, obj) {
      await feathersClient.service("/api/v1/senderaddresses").create({
        phoneNumber: obj.phoneNumber,
        firstName: obj.firstName,
        lastName: obj.lastName,
        addressLine1: obj.addressLine1,
        addressLine2: obj.addressLine2,
        aadharFrontUrl: obj.aadharFrontUrl,
        aadharBackUrl: obj.aadharBackUrl,
      });
      getSenderDetails();
    },

    async getSenderDetails({ commit, state }) {
      const result = await feathersClient
        .service("/api/v1/senderaddresses")
        .find({
          query: {},
        });
      console.log("GetDetails -> ",result.data[0]);
      commit("setDetails", result.data[0]);
    },
  },
};
