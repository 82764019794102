import feathersClient from '../../../feathers-client'
export default {
  namespaced: true,
  state: {
    itemsToBeShipped: '',
    id: '',
    Weight: '',
	  shippingPartnerPrices: [],
    allPaymentsCompletedPackages: [],
},
  mutations: {
    SET_WEIGHT(state, val) {
      state.Weight=val;
    },
    SET_ITEMS(state, val) {
      state.itemsToBeShipped = val
    },
    SET_ID(state, val) {
      state.id = val
    },
    SET_SHIPPING_PARTNER_PRICES(state, val){
      state.shippingPartnerPrices.push(val);
    },
    RESET_SHIPPING_PARTNER_PRICES(state){
      state.shippingPartnerPrices=[];
    },
    SET_ALL_RAZORPAY_ORDERS(state, val) {
      state.allPaymentsCompletedPackages = [...val];
    }
  },
  actions: {
    getShippedItems: async ({ commit,state }) => {
      const result = await feathersClient.service('/api/v1/shippingpackage').find({
        query: {
          $limit: 1000,
          $sort: {
            date: -1
          },
          isCancelled: false
        }
      })
      for (let j=0; j<result.data.length; j++){
      if (result.data[j].weight){

      if (!parseFloat(result.data[j].volumetricWeight)){
        commit('SET_WEIGHT', Math.ceil(parseFloat(result.data[j].weight)));
      }
      else if (parseFloat(result.data[j].weight) > parseFloat(result.data[j].volumetricWeight)){
    		commit('SET_WEIGHT', Math.ceil(parseFloat(result.data[j].weight)));

      }
      else{
        commit('SET_WEIGHT', Math.ceil(parseFloat(result.data[j].volumetricWeight)));
      }
      const Result = await feathersClient.service('/api/v1/shippingprices').find({
        query: {
          weightRangeMin: {
            $lte: state.Weight
          },
          weightRangeMax: {
            $gte: state.Weight
          },
        }
      });
      commit('RESET_SHIPPING_PARTNER_PRICES')

      for (let index = 0; index < Result.data.length; index++) {
    		const unitPrice = Result.data[index].price;
		    const shippingPrice = unitPrice * state.Weight;
    		const shippingPartnerPrice= [Result.data[index].shippingPartner[0].shippingpartners,shippingPrice]
        commit('SET_SHIPPING_PARTNER_PRICES', shippingPartnerPrice);
      }
      result.data[j]['shippingDetails']=state.shippingPartnerPrices
    }
    

      }
   
  commit('SET_ITEMS', result.data)
  },
  getPaymentCompletedDate: async ({commit, state}, payload) => {
    let query = {query: {
      $limit: 1000,
      id: payload
    }}
    query["query"]["fields"] = ["razorpaypayment"]
    const result = await feathersClient.service('api/v1/shippingpackage').find(query)
    commit('SET_ALL_RAZORPAY_ORDERS', result.data);
   },
   updateValue: async({commit, state}, payload) => {
     await feathersClient.service('api/v1/userbinitems').patch(payload.id, {
       value: payload.value
     })
   }
  },
}
