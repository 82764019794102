import dashboard from './Dashboard'
import register from './register'
import HomePage from './HomePage'
import Payment from './Payment'
import ShippingCostCalculator from './ShippingCostCalculator'
import ConsolidationStatus from './ConsolidateStatus'
import Admin from './adminPages'
import Store from './Store'
import PrivacyPolicy from './privacyPolicy'
import Faq from './faq'
import Services from './services'
import Login from './Login'
import TermsAndConditions from './TermsAndConditions'
import HowItWorks from './HowItWorks'
import ProhibitedItems from './ProhibitedItems'
import AboutUs from './aboutUs'

// import Login from './Login'

const data = [
    ...Login,
    ...HomePage,
    ...register,
    ...ShippingCostCalculator,
    ...dashboard,
    ...ConsolidationStatus,
    ...Payment,
    ...Admin,
    ...Store,
    ...PrivacyPolicy,
    ...TermsAndConditions,
    ...Faq,
    ...HowItWorks,
    ...Services,
    ...ProhibitedItems,
    ...AboutUs
]

export default data
