import Vue from "vue";
import Vuex from "vuex";
import feathersClient from "../../../feathers-client";

Vue.use(Vuex);

export default{
    namespaced: true,
    state:{
        toBeConsolidateTotal: 0,
        toBeConsolidated: [],
        toBeConsolidate1: [],
        inProgress: [],
        inProgressTotal: [],
        awaitingShipment: [],
        awaitingShipmentTotal: [],
        shippingDone: [],
        shippingDoneTotal: [],
        status_consol: [],
        status_inProg: [],
        status_await: [],
        status_ship: [],
    },
    mutations:{
        SET_TO_BE_CONSOLIDATE_TOTAL(state, val){
            state.toBeConsolidateTotal = val
        },
        SET_TOBE_CONSOLIDATED(state, val) {
            state.toBeConsolidated = val;
          },
        SET_IN_PROGRESS(state, val) {
            state.inProgress = val;
        },
        SET_IN_PROGRESS_TOTAL(state, val) {
            state.inProgressTotal = val
        },
        SET_AWAITING_SHIPMENT(state, val) {
            state.awaitingShipment = val;
        },
        SET_AWAITING_SHIPMENT_TOTAL(state, val) {
            state.awaitingShipmentTotal = val;
        },
        SET_SHIPPING_DONE(state, val) {
            state.shippingDone = val;
        },
        SET_SHIPPING_DONE_TOTAL(state, val) {
            state.shippingDoneTotal = val;
        },
        SET_STATUS_CONSOL(state, val) {
            state.status_consol = val;
        },
        SET_STATUS_INPROG(state, val) {
            state.status_inProg = val;
        },
        SET_STATUS_WAIT(state, val) {
            state.status_await = val;
        },
        SET_STATUS_SHIP(state, val) {
            state.status_ship = val;
        },
    },
    actions:{
        toBeConsolidateItems: async ({ state, commit }, payload) => {
            const result = await feathersClient.service("/api/v1/shippingpackage").find({
                query: {
                    $limit: payload.limit,
                    $sort:{
                        createdAt: -1
                    },
                    paymentStatus: payload.paymentStatus,
                    shippingStatus: payload.shippingStatus,
                    fields: "userDetails",
                    isCancelled: false
                },
                paginate: false
            })
            commit("SET_TOBE_CONSOLIDATED", result.data)
            commit('SET_TO_BE_CONSOLIDATE_TOTAL', result.total)
            },
            toBeConsolidateItemsStatus: async ({ state, commit }, payload) => {
                const result = await feathersClient.service("/api/v1/shippingpackage").find({
                    query: {
                        $limit: payload.limit,
                        $sort:{
                            createdAt: -1
                        },
                        paymentStatus: payload.paymentStatus,
                        shippingStatus: payload.shippingStatus,
                        fields: "userDetails",
                        isCancelled: false
                    },
                    paginate: false
            })
            commit('SET_STATUS_CONSOL', result.data)
            commit('SET_TO_BE_CONSOLIDATE_TOTAL', result.total)
        },
        inProgressItems: async ({ state, commit }, payload) => {
            const result = await feathersClient.service("/api/v1/shippingpackage").find({
                query: {
                    $limit: payload.limit,
                    $sort:{
                        createdAt: -1
                    },
                    paymentStatus: payload.paymentStatus,
                    shippingStatus: payload.shippingStatus,
                    fields: "userDetails",
                    isCancelled: false
                },
                paginate: false
            })
            commit("SET_IN_PROGRESS", result.data)
            commit('SET_IN_PROGRESS_TOTAL', result.total)
        },
        inProgressItemsStatus: async ({ state, commit }, payload) => {
            const result = await feathersClient.service("/api/v1/shippingpackage").find({
                query: {
                    $limit: payload.limit,
                    $sort:{
                        createdAt: -1
                    },
                    paymentStatus: payload.paymentStatus,
                    shippingStatus: payload.shippingStatus,
                    fields: "userDetails",
                    isCancelled: false
                },
                paginate: false
        })
        commit('SET_STATUS_INPROG', result.data)
        commit('SET_IN_PROGRESS_TOTAL', result.total)
        },
        awaitingShipmentItems: async ({ state, commit }, payload) => {
            const result = await feathersClient.service("/api/v1/shippingpackage").find({
                query: {
                    $limit: payload.limit,
                    $sort:{
                        createdAt: -1
                    },
                    paymentStatus: payload.paymentStatus,
                    shippingStatus: payload.shippingStatus,
                    fields: "userDetails",
                    isCancelled: false
                },
                paginate: false
            })
            commit("SET_AWAITING_SHIPMENT", result.data)
            commit('SET_AWAITING_SHIPMENT_TOTAL', result.total)
        },
        awaitingShipmentItemsStatus: async ({ state, commit }, payload) => {
            const result = await feathersClient.service("/api/v1/shippingpackage").find({
                query: {
                    $limit: payload.limit,
                    $sort:{
                        createdAt: -1
                    },
                    paymentStatus: payload.paymentStatus,
                    shippingStatus: payload.shippingStatus,
                    fields: "userDetails",
                    isCancelled: false
                },
                paginate: false
        })
        commit('SET_STATUS_WAIT', result.data)
        commit('SET_AWAITING_SHIPMENT_TOTAL', result.total)
        },
        shippingDoneItems: async ({ state, commit }, payload) => {
            const result = await feathersClient.service("/api/v1/shippingpackage").find({
                query: {
                    $limit: payload.limit,
                    $sort: {
                        createdAt: -1
                    },
                    paymentStatus: payload.paymentStatus,
                    shippingStatus: payload.shippingStatus,
                    fields: "userDetails",
                    isCancelled: false
                },
                paginate: false
            })
            commit("SET_SHIPPING_DONE", result.data)
            commit('SET_SHIPPING_DONE_TOTAL', result.total)
        },
        shippingDoneItemsStatus: async ({ state, commit }, payload) => {
            const result = await feathersClient.service("/api/v1/shippingpackage").find({
                query: {
                    $limit: payload.limit,
                    $sort:{
                        createdAt: -1
                    },
                    paymentStatus: payload.paymentStatus,
                    shippingStatus: payload.shippingStatus,
                    fields: "userDetails",
                    isCancelled: false
                },
                paginate: false
        })
        commit('SET_STATUS_SHIP', result.data)
        commit('SET_SHIPPING_DONE_TOTAL', result.total)
        },
    },
};
    