import Vue from 'vue'
import Vuex from 'vuex'

import feathersClient from '../../../feathers-client'

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    //FIXME: can we club firstname, lastname, email, contactNumber in user-object
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    uid: null,
  },
  getters: {

  },
  mutations: {
    SET_FIRSTNAME(state, val) {
      state.firstName = val
    },
    SET_LASTNAME(state, val) {
      state.lastName = val
    },
    SET_EMAIL(state, val) {
      state.email = val
    },
    SET_CONTACT(state, val) {
      state.contactNumber = val
    },
    SET_USER_DETAILS(state, uo) {
      state.uid = uo.id;
      state.firstName = uo.firstName;
      state.lastName = uo.lastName;
      state.email = uo.email;
      state.contactNumber = uo.phone;
    },
  },
  actions: {
    updateDetails: async ({ state }, payload) => {
      await feathersClient.service('/api/v1/users').patch(state.uid, {
        firstName: payload.firstName,
        lastName: payload.lastName,
        // email: state.email,
        phone: payload.contact,
      }, {})
      getUserDetails()
    },

    getUserDetails: async ({ commit, state }) => {
      const result = await feathersClient.service('/api/v1/users').find({ query: {} });
      console.log(result.data[0]);
      commit('SET_USER_DETAILS', result.data[0]);
    },
    // changePassword: async ({ commit, state }, payload) => {
    //   try{
    //   await feathersClient.service('/api/v1/authmanagement').create({
    //     action: 'passwordChange',
    //     value: {
    //       user: payload.email,
    //       oldPassword: payload.oldPass,
    //       password: payload.newPass,
    //     },
    //   })
    // }
    //   catch(error){
    //     console.log(error)
    //   }
    // }
  },
}
