export default [
    {
        path: '/consolidationstatus',
        name: 'consolidation-status',
        component: () => import('@/yicpages/dashboard/MyOrders/ConsolidationStatus.vue'),
        meta: {
            layout: 'full',
            authRequired: true,
        },
    },
]