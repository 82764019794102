import feathersClient from '../../../feathers-client'
export default {
  namespaced: true,
  state: {
    testimonials: null,
  },
  getters: {

  },
  mutations: {
    SET_TESTIMONIALS(state, val) {
      state.testimonials = val;
    },
  },
  actions: {
    extractTestimonials: async ({ commit, state }) => {
      const result = await feathersClient.service('/api/v1/testimonial').find({
        query: {

        }
      });
      commit('SET_TESTIMONIALS', result.data);
      // console.log(result.data);
    }
  },
}