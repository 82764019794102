export default [
	{
	  path: '/login',
	  name: 'login',
	  component: () => import('@/yicpages/login/Login.vue'),
	  meta: {
		layout: 'full',
	  },
	  params: {
		
	  }
	},
	{
		path: '/forgot-password',
		name: 'forgot-password',
		component: () => import('@/yicpages/login/forgotPass.vue'),
		meta: {
		  layout: 'full',
		},
	  },
	  {
		path: "/reset/:token",
		name: "resetPwd",
		component: () =>
		  import("@/yicpages/login/resetPassword.vue"),
		meta: {
		  layout: "full",
		//   authRequired: true,
		},
	  },
  ]
  