import feathers from '@feathersjs/feathers'
import rest from '@feathersjs/rest-client'
import auth from '@feathersjs/authentication-client'
import { iff, discard } from 'feathers-hooks-common'


const hostprefix = window.location.hostname.split('.')[0];
let apiUrl = ''
switch (hostprefix) {
  case 'staging':
    apiUrl = 'https://api-staging.yourindiancart.com'
    break;
  case 'app':
    apiUrl = 'https://api.yourindiancart.com'
    break;
  case 'www':
    apiUrl = 'https://api.yourindiancart.com'
    break;
  case 'yourindiancart':
    apiUrl = 'https://api.yourindiancart.com'
    break;
  default:
    apiUrl = 'http://localhost:3030'
    break;
}

// const socket = io(apiUrl, {transports: ['websocket']})

// Connect to a different URL
const restClient = rest(apiUrl)

const feathersClient = feathers()
  .configure(restClient.fetch(window.fetch.bind(window)))
  .configure(auth({ storage: window.localStorage, path: 'api/v1/authentication' }))
  .hooks({
    before: {
      all: [
        iff(
          context => ['create', 'update', 'patch'].includes(context.method),
          discard('__id', '__isTemp')
        )
      ]
    }
  })

export default feathersClient
