import Vue from "vue";
import Vuex from "vuex";
import feathersClient from "../../../feathers-client";
Vue.use(Vuex);

export default {
  namespaced: true,
  state:{
    errors: '',
    success: false,
    data: [],
    items: [],
    paymentDetails: {},
    orderDetails: [],


  },
  getters:{},
  mutations: {
    
    SET_ERROR(state,val){
      state.errors = val
    },
    SET_SUCCESS(state,val){
      state.success = val
    },
    

  },
  actions:{
//     fetchData: async ({ commit, state }, payload) => {
//       try{
//         state.data = []
//         state.items=[]
//         state.paymentDetails=[]
// 		const result = await feathersClient.service('/api/v1/shippingpackage').find({
// 			query:{
// 			$total: true,
// 			paymentStatus: 'Done',
// 			// fulfilmentStatus: 'Pending',
// 		  }
// 		});
// 		for(var i=0; i<result.length;i++){
// 			for (var j=0; j<result[i].selectedItems.length;j++){
// 				if (result[i].selectedItems[j].fulfilmentStatus == 'Pending' && result[i].selectedItems[j].itemSourceType == 'store'){
//           // if (result[i].selectedItems[j].vendororderitemId == null){
//             state.data.push(result[i].selectedItems[j])
//           // }
//           // console.log("order creation items",result[i].selectedItems[j])
//           // state.data.push(result[i].selectedItems[j]);
// 				}
// 			}
// 		}
//     // let items = []
//     // for (var i=0; i<state.data.length; i++){
//     //   for (var j=0; j<state.data[i].selectedItems.length; j++){
//     //     items.push(state.data[i].selectedItems[j])
//     //   }
//     // }
//     let dicts={}
//     let vendors = []
//     const res = await feathersClient.service('/api/v1/vendors').find({
//       query:{
//         $limit: 1000
//       }
//     }, {})
//     res.data.map(ven => {
//       let v = {
//         id: ven.id,
//         name: ven.name
//       }
//       vendors.push(v)
//     })
//     console.log("response", res)
//     let dict=[]
//     // console.log(dict)
//     for(var j=0; j<state.data.length; j++){
//       if(!dict.includes(items[j].vendorId)){
//         dict.push(items[j].vendorId)
//         dicts[items[j].vendorId] = [items[j]]
//       }else{
//         dicts[items[j].vendorId]?.push(items[j])
//       }
//     }
//     console.log("dicts", dicts)
    
//     for(const [key, value] of Object.entries(dicts)){
//     const resId = await feathersClient.service('/api/v1/vendororders').create({
//       OrderedDate : Date(),
//       OrderNo : Date.now() + Math.floor(Math.random()*9999),
//       OrderStatus: 'Ordered',
//       vendorId: key
//     })
//     for (var i=0; i<items.length; i++){
//       if (items[i].vendorId==key){
//       const res = await feathersClient.service('/api/v1/vendororderitems').create({
//         ProductId : items[i].itemName,
//         quantity : items[i].quantity,
//         VariantId : 'random',
//         vendororderId : resId.id
//       })
//       await feathersClient.service('/api/v1/userbinitems').patch(items[i].id,{
//         vendororderitemId: res.id
//       })
//     }
//   }
//   let totalAmt = 0
//   for(const [key, value] of Object.entries(dicts)){
//   const res = await feathersClient.service('/api/v1/vendors').find({
//     query:{
//       id: key
//     }
//   })
//   for (var j=0; j<dicts[key].length;j++){
//     let arr={}
//     arr['item'] = dicts[key][j].itemName
//     arr['qty'] = dicts[key][j].quantity
//     arr['rate'] = dicts[key][j].value
//     arr['vendor'] = res.data[0].name
//     totalAmt = totalAmt + arr['rate']
//     state.items.push(arr)
//   }
// }
// state.paymentDetails['SubTotal'] = totalAmt
// state.paymentDetails['Discount'] = 0
// state.paymentDetails['Tax'] = 0
// state.paymentDetails['Total'] = totalAmt
// }
  


//     commit('SET_ERROR','')
// 		commit('SET_SUCCESS',true)
//   }catch(e){
// 			commit('SET_ERROR',e)

//   }
// 	  },
    fetchData : async({commit, state}, payload) => {
      await feathersClient.service('/api/v1/createorder').find({
        // query:{
        //   payload: payload
        // }
      })
    }
    }
}
