import Vue from "vue";
import Vuex from "vuex";

import feathersClient from "../../../feathers-client";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
   trackingUrl: '',
   shippingPackageURL: '',
  },
  mutations: {
   SET_TRACKINGURL(state,val){
	state.trackingUrl = val.trackingURL;
   },
   
  },
  actions: {
   getTrackingUrl: async ({commit},payload)=>{
	const res2 = await feathersClient.service('/api/v1/shippingpartners').find({
		query:{
			shippingpartners: payload.shippingPartner
		}
	})
	commit('SET_TRACKINGURL',res2.data[0])
   },
   getShippingPackageUrl: async ({state},payload)=>{
      const res = await feathersClient.service('/api/v1/shippingpackage').find({
         query:{
            id: payload.packageId
         }
      })
      state.shippingPackageURL = res.shippingPackageURL

   }

  },
};
