import feathersClient from '../../../feathers-client'
export default {
  namespaced: true,
  state: {
    bannerContent: null,
  },
  getters: {

  },
  mutations: {
    SET_BANNERCONTENT(state, val) {
      state.bannerContent = val;
    },
  },
  actions: {
    extractContent: async ({ commit, state }) => {
      const result = await feathersClient.service('/api/v1/homepagecarousel').find({
        query: {}
      });
      commit('SET_BANNERCONTENT', result.data);
      // console.log(result.data);
    }
  },
}