import Vue from "vue";
import Vuex from "vuex";

import feathersClient from "../../../feathers-client";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    // addresses: [],
    addressLine1: "",
    addressLine2: "",
    city: "",
    stt: "",
    zipCode: "",
    country: "",
    fullName: "",
    phone: "",
    // userId: null,
  },
  getters: {},
  mutations: {
    // storeAddresses: (state, data) => {
    //   state.addresses = data;
    // },
    SET_ADDRL1: (state, data) => {
      state.addressLine1 = data;
    },
    SET_ADDRL2: (state, data) => {
      state.addressLine2 = data;
    },
    SET_CITY: (state, data) => {
      state.city = data;
    },
    SET_STATE: (state, data) => {
      state.stt = data;
    },
    SET_ZIPCODE: (state, data) => {
      state.zipCode = data;
    },
    SET_COUNTRY: (state, data) => {
      state.country = data;
    },
    SET_FULL_NAME: (state, data) => {
      state.fullName = data;
    },
    SET_PHONE: (state, data) => {
      state.phone = data;
    },
  },
  actions: {
    editDeliveryAddress: async ({ state }, payload) => {
      let ID = payload.ID;
      // console.log("ID");
      await feathersClient.service("/api/v1/deliveryaddress").patch(ID,
        {
          addressLine1: state.addressLine1,
          addressLine2: state.addressLine2,
          city: state.city,
          state: state.stt,
          zipCode: state.zipCode,
          country: state.country,
          fullName: state.fullName,
          phone: state.phone,
          userId: state.userId,
        },
        {}
      );
    //   getAllAddresses();
    },
    fetchSelectedAddress: async ({ commit }, payload) => {
      // console.log(payload);
      let ID = payload.ID;
      const result = await feathersClient
        .service("/api/v1/deliveryaddress")
        .find({
          query: {
            id: ID,
          },
        });
        // console.log(result.data);commitcommit.log("SET_ADDRL1", result.data[0].addressLine1);
      commit("SET_ADDRL1", result.data[0].addressLine1);
      commit("SET_ADDRL2", result.data[0].addressLine2);
      commit("SET_CITY", result.data[0].city);
      commit("SET_STATE", result.data[0].state);
      commit("SET_ZIPCODE", result.data[0].zipCode);
      commit("SET_COUNTRY", result.data[0].country);
      commit("SET_FULL_NAME", result.data[0].fullName);
      commit("SET_PHONE", result.data[0].phone);
    },
  },
};
