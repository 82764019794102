import Vue from "vue";
import Vuex from "vuex";

import feathersClient from "../../../feathers-client";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
   
  },
  mutations: {
   
   
  },
  actions: {
    resetPwd: async ({ commit }, payload) => {
      const verify = await feathersClient
        .service("/api/v1/authmanagement")
        .create({
          action: "sendResetPwd",
          value: {
            email: payload.email,
          },
        });
    },
    changePass: async ({ commit }, payload) => {
      try {
        
        await feathersClient.service('/api/v1/authmanagement').create({
          'action': 'resetPwdLong',
          'value': {
            'token': payload.token,
            'password': payload.password,
          },
        })
      }
      catch(error){
        console.log(error)
      }
      
      // console.log("res"+JSON.stringify(res));
    }

  },
};
