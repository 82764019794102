export default [
    {
        path: '/terms-and-conditions',
        name: 'TermsAndConditions',
        component: () => import('@/yicpages/TermsAndConditions/TermsAndConditions.vue'),
        meta: {
            layout: 'full',
        },
    }
]
