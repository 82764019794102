
import feathersClient from "../../../feathers-client";
export default {
	namespaced: true,
	state: {
		errors: '',
		success: false,
	}, 
	getters: {},
	mutations: {
		SET_ERROR(state, val) {
			state.errors = val
		},
		SET_SUCCESS(state, val) {
			state.success = val
		}
	},
	actions: {
		addStore: async ({ commit, state }, obj) => {
			//-=-=-=-=-= deleting previous stored things first==========
			// let res = await feathersClient.service('/api/v1/products').find({

			// });
			// await res.data.map(async e => {
			// 	const res = await feathersClient.service('/api/v1/products').remove(e.id)

			// })

			// res = await feathersClient.service('/api/v1/productcategories').find({

			// });
			// await res.data.map(async e => {
			// 	const res = await feathersClient.service('/api/v1/productcategories').remove(e.id)

			// })
			// res = await feathersClient.service('/api/v1/variants').find({});
			// await res.data.map(async e => {
			// 	const res = await feathersClient.service('/api/v1/variants').remove(e.id)
			// })
			//-===-=-=-=-=///////////////////////

			for (let indx = 0; indx < obj.length; indx++) {
				const e = obj[indx];

				// ==========================Product category==========================
				let productCategoryId = null;
				// console.log(e, indx);
				const { TopCategory } = e;
				if (TopCategory === '') continue;// empty row
				else {
					let res = await feathersClient.service('/api/v1/productcategories').find({
						query: {
							name: TopCategory
						}
					});
					if (res.data.length === 0) {//creating new product category
						res = await feathersClient.service('/api/v1/productcategories').create({
							name: TopCategory,
							banner: [{ "bannerHeading": "For every occassion", "bannerDescription": "Order your favourite sweets right from India", "imageUrl": "xyz" }, { "bannerHeading": "For every occassion2", "bannerDescription": "Order your favourite sweets right from India", "imageUrl": "xyz" }]
						});
						productCategoryId = res.id;
					}
					if (!productCategoryId) productCategoryId = res.data[0].id;
				}
				
				// creating vendors
				let vendorId = null
				let {Vendor} = e
				if(Vendor){
					let res = await feathersClient.service('/api/v1/vendors').find({
						query:{
							name: Vendor
						}
					})
					if(res.data.length == 0){
						res = await feathersClient.service('/api/v1/vendors').create({
							name: Vendor
						})
						vendorId = res.id
					}
					if(!vendorId){
						vendorId = res.data[0].id
					}
				}
				



				// ======================================= subCategory =======================================


				// subcategory can be null also..

				let subCategoryId = null;
				const { SubCategory1 } = e;
				if (SubCategory1) {
					let res = await feathersClient.service('/api/v1/storesubcategories').find({
						query: {
							name: SubCategory1,
							productcategoryId: productCategoryId,
						}
					});
					if (res.data.length === 0) {//creating new subCategory
						res = await feathersClient.service('/api/v1/storesubcategories').create({
							name: SubCategory1,
							productcategoryId: productCategoryId,
						});
						subCategoryId = res.id;
					}
					if (!subCategoryId) subCategoryId = res.data[0].id;
				}

				// =============================== Product  ===============================
				let productId = null;
				const { ProductSKU } = e;
				// product already there -> finding product
				let res = await feathersClient.service('/api/v1/products').find({
					query: {
						SKU: ProductSKU
					}
				});
				//creating new product
				if (res.data.length === 0) {
					res = await feathersClient.service('/api/v1/products').create({
						SKU: ProductSKU
					});
					productId = res.id;
				}
				if (!productId)
					productId = res.data[0].id;
				const pro = await feathersClient.service('/api/v1/products').patch(productId, {
					name: e.Title,
					description: e.Description,
					productImages: [e.ImageSrc1, e.ImageSrc2, e.ImageSrc3],
					subCategory: e.SubCategory1,
					status: e.ProductStatus,
					tags: e.Tags,
					productcategoryId: productCategoryId,
					storesubcategoryId: subCategoryId,
					vendorId: vendorId
				});








				// =============================== variant creation ===============================
				let i = 0, x = 10;
				while (x--) {
					i++;
					let str = `VariantSKU${i}`;
					let SKU = e[str];
					if (SKU === '') break;//terminating condition!
					let variantId = null;
					res = await feathersClient.service('/api/v1/variants').find({
						query: {
							SKU: SKU
						}
					});
					// creating new variant
					if (res.data.length === 0) {
						res = await feathersClient.service('/api/v1/variants').create({
							SKU: SKU
						});
						variantId = res.id;
					}
					if (!variantId) variantId = res.data[0].id;
					// `VariantTitle${num}`
					const vari = await feathersClient.service('/api/v1/variants').patch(variantId, {
						title: e[`VariantTitle${i}`],
						name: e[`VariantName${i}`],
						priceOffer: e[`VariantOfferPrice${i}`] === '' ? null : e[`VariantOfferPrice${i}`],
						priceOriginal: e[`VariantActualPrice${i}`],
						inventoryQuantity: e[`VariantQuantity${i}`],
						status: e[`VariantStatus${i}`],
						productId: productId,
						shippingWeight: parseFloat(e[`VariantShippingWeight${i}`])
					});
					// console.log(e, indx);
				}
			}
			commit('SET_SUCCESS', true)
		},
		// Delete all the 'Store related things' !
		deleteStore: async ({ commit, state }, obj) => {
			let res = await feathersClient.service('/api/v1/products').find({
				query: {
					$limit: 500
				}
			});
			await res.data.map(async e => {
				const res = await feathersClient.service('/api/v1/products').remove(e.id)

			})
			res = await feathersClient.service('/api/v1/productcategories').find({
				query: {
					$limit: 500
				}
			});
			await res.data.map(async e => {
				const res = await feathersClient.service('/api/v1/productcategories').remove(e.id)

			})
			res = await feathersClient.service('/api/v1/variants').find({
				query: {
					$limit: 500
				}
			});
			await res.data.map(async e => {
				const res = await feathersClient.service('/api/v1/variants').remove(e.id)
			})
			res = await feathersClient.service('/api/v1/vendors').find({
				query: {
					$limit: 500
				}
			});
			await res.data.map(async e => {
				const res = await feathersClient.service('/api/v1/vendors').remove(e.id)
			})
			commit('SET_SUCCESS', true)

		}
	}
}