export default [
  {
    path: '/register-demo',
    name: 'register-demo',
    component: () => import('@/yicpages/register/RegisterDemographics.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/register-pass',
    name: 'register-pass',
    component: () => import('@/yicpages/register/RegisterPassword.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/verifyEmail/:token',
    name: 'verify',
    component: () => import('@/yicpages/register/VerifyEmail.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/loginv2',
    name: 'login',
    component: () => import('@/yicpages/login/Login.vue'),
    meta: {
      layout: 'full',
    },
  },
  
]
