import Vue from "vue";
import Vuex from "vuex";
import feathersClient from "../../../feathers-client";
Vue.use(Vuex);

export default {
  namespaced: true,
  state:{
    merchants:[],
    totalMerchants:0,
    merch: [],
    total: 0

  },
  getters:{},
  mutations: {
    allMerchants : (state,result) =>{
      state.merchants = result.data,
      state.totalMerchants = result.total
    },
    SET_ALL_MERCHANT(state, val){
      state.merch = val.data,
      state.total = val.total
    }

  },
  actions:{
    addNewMerchants:async ({dispatch,state, commit},payload) =>{
      try {
        var response = await feathersClient.service('api/v1/merchants').create({
          name:payload
        })
        console.log("new merchant res"+JSON.stringify(response))
        var query={
          skip:0,
          limit:10
        }
        dispatch("fetchAllMerchants",query)
      } catch (e) {
        console.log("error occured while creating categories"+JSON.stringify(e))
        }
      },
      loadSearchRes : async({dispatch,state,commit},payload) =>{
        payload["$specialSearch"] = true;
        payload["fields"] = ['users']
        console.log("loadSearchRes payload value"+JSON.stringify(payload));
        var res = await feathersClient.service("api/v1/merchants").find({query:payload})
        console.log("res of loadSearchRes =="+JSON.stringify(res));
        commit("setSearchRes",res)
      },
      fetchAllMerchants : async({store,commit},payload) =>{
        //console.log("skip and limit values =="+JSON.stringify(payload));
        const result = await feathersClient
          .service("/api/v1/merchants")
          .find({
            query: {
              $skip:payload.skip,
              $limit:payload.limit,
              $sort:{
                updatedAt:-1
              }
            },
          });
        console.log("fetchAllMerchants response =="+JSON.stringify(result));
        commit("allMerchants", result);
      },
      fetchMerchants: async({state, commit}, payload) => {
        payload["$specialSearch"] = true;
        const res = await feathersClient.service('/api/v1/merchants')
                    .find({
                      query: payload
          })
          commit('SET_ALL_MERCHANT', res);
      },
      deleteMerchant:async({dispatch,state,commit},id) =>{
        var query={
          skip:0,
          limit:10
        }
        const result = await feathersClient.service("/api/v1/merchants").remove(id, {});
        console.log("deleted : ", result);
        dispatch("fetchAllMerchants",query)
      },
      editMerchant:async({dispatch,state,commit},payload) =>{
        var query={
          skip:0,
          limit:10
        }
        const result = await feathersClient.service("/api/v1/merchants").patch(payload.id, {name:payload.name});
        console.log("updatedRes of merchants : ", result);
        dispatch("fetchAllMerchants",query)
      }
    }
}
