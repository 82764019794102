export default [
    {
        path: '/store',
        name: 'storeMain',
        component: () => import('@/yicpages/Store/Store.vue'),
        meta: {
            layout: 'full',
            // authRequired: true,
        },
        children: [
            {
                path: '',
                name: 'store',
                component: () => import('@/yicpages/Store/StoreLandingPage.vue'),
                meta: {
                    layout: 'full',
                    // authRequired: true,
                },
            },
            {
                path: 'cart',
                name: 'cart',
                component: () => import('@/yicpages/Store/ShoppingCart/Cart.vue'),
                meta: {
                    layout: 'full',
                    // authRequired: true,
                }
            },
            {
                path: 'product/:id',
                name: 'productdescription',
                component: () => import('@/yicpages/Store/ProductDescription/Description.vue'),
                meta: {
                    layout: 'full',
                    // authRequired: true,
                }
            },
            {
                path: 'payment',
                name: 'storepayment',
                component: () => import('@/yicpages/Store/payment/StorePayment.vue'),
                meta: {
                    layout: 'full',
                    // authRequired: true, // make this true
                }
            },
            {
                path: 'category/:categoryName',
                name: 'categoryStore',
                component: () => import('@/yicpages/Store/LandingPageItems/CategoryItems.vue'),
                meta: {
                    layout: 'full',
                    // authRequired: true, // make this true
                },
                // props: (route) => ({
                //     category: route.query.category,
                //     subCategory: route.query.subCategory
                // }),
            }

        ]
    },
]