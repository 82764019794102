import feathersClient from '../../../feathers-client'
import itemsinusersuite from '../itemsinusersuite';

export default {
    namespaced: true,
    state: {
        paymentGatewayOption: '',
    },
    mutations: {
        SET_PAYMENT_GATEWAY_OPTION(state, val) {
            state.paymentGatewayOption = val
        },
    },
    actions: {
        setPaymentGatewayOption: async ({commit, state}, payload) => {
            const paymentOption = await feathersClient.service('api/v1/paymentgatewayselection').find({})
            commit('SET_PAYMENT_GATEWAY_OPTION', paymentOption)
        },
    },
}
