import feathersClient from '../../../feathers-client'

export default {
  namespaced: true,
  state: {
    pickUpRequestId: '',
    pickupPackagesDetails: '',
    firstName: '',
    lastName: '',
    contact: '',
    addressLine1: '',
    addressLine2: '',
    pinCode: '',
    city: '',
    st: '',
    country: '',
    packageDescription: '',
    weight: '',
    packageDimension: '',
    trackingNumber: '',
    flag: false,
    deliveryAddressId: null,
    shipDirectly: false,
  },
  mutations: {
    SET_PICKUP_REQUEST_ID(state, val) {
        state.pickUpRequestId = val
    },
    SET_PICKUP_PACKAGES(state, val) {
        state.pickupPackagesDetails = val
    },
    SET_PICKUP_PERSONS_INFO(state, val) {
        state.flag = false
        state.firstName = val.firstName,
        state.lastName = val.lastName,
        state.contact = val.phone
        if(state.firstName!='' && state.lastName!='' && state.contact!='') {
            state.flag = true
        }
    },
    SET_PICKUP_ADDRESS_DETAILS(state, val) {
        state.flag = false
        state.addressLine1 = val.addLine1,
        state.addressLine2 = val.addLine2,
        state.city = val.city,
        state.country = val.country,
        state.pinCode = val.pin,
        state.st = val.state
        if(state.addressLine1!='' && state.addressLine2!='' && state.pinCode!=''
        && state.city!='' && state.country!='' && state.st!='') {
            state.flag = true
        }
    },
    SET_PICKUP_PACKAGE_DETAILS(state, val) {
        state.flag = false
        state.packageDescription = val.pkgDesc,
        state.packageDimension = val.pkgDimensions,
        state.weight = val.weight,
        state.trackingNumber = val.trackingNo
        if(state.packageDescription!='' && state.weight!='') {
            state.flag = true
        }
    },
    SET_DELIVERY_ADDRESS_ID(state, val){
        state.deliveryAddressId = val
    },
    SET_SHIP_DIRECTLY(state, val){
        state.shipDirectly = val
    },
  },
  actions: {
    setAllPickupFields: async({commit, state}) => {
        if(state.flag == true) {
            let query = {
                firstName: state.firstName,
                lastName: state.lastName,
                contactNumber: state.contact,
                shipDirectly: state.shipDirectly
            }
            if(state.deliveryAddressId){
                query['deliveryaddressId'] = state.deliveryAddressId
            }
            const response = await feathersClient.service('/api/v1/pickuprequests').create(query)
            commit("SET_PICKUP_REQUEST_ID",response.id)
            await feathersClient.service('/api/v1/pickupaddresses').create({
                addressLine1: state.addressLine1,
                addressLine2: state.addressLine2,
                zipCode: state.pinCode,
                city: state.city,
                state: state.st,
                country: state.country,
                pickuprequestId: state.pickUpRequestId
            })
            await feathersClient.service('/api/v1/pickuppackages').create({
                packageDescription: state.packageDescription,
                weight: state.weight,
                packageDimension: state.packageDimension,
                trackingNumber: state.trackingNumber,
                pickuprequestId: state.pickUpRequestId,
            })
        }
    },
    getPickUpPackages: async({commit, state}) => {
        const response = await feathersClient.service('/api/v1/pickuprequests').find({
            query: {
                $limit: 1000,
                $sort: {
                    createdAt: -1
                }
            }
        })
        commit('SET_PICKUP_PACKAGES', response.data)
    },
    
}
}