import Vue from "vue";
import Vuex from "vuex";

import feathersClient from "../../../feathers-client";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    addresses: [],
    addressLine1: "",
    addressLine2: "",
    city: "",
    stt: "",
    zipCode: "",
    country: "",
    fullName: "",
    phone: "",
    userId: null,
  },
  getters: {},
  mutations: {
    storeAddresses: (state, data) => {
      state.addresses = data;
    },
    SET_ADDRL1: (state, data) => {
      state.addressLine1 = data;
    },
    SET_ADDRL2: (state, data) => {
      state.addressLine2 = data;
    },
    SET_CITY: (state, data) => {
      state.city = data;
    },
    SET_STATE: (state, data) => {
      state.stt = data;
    },
    SET_ZIPCODE: (state, data) => {
      state.zipCode = data;
    },
    SET_COUNTRY: (state, data) => {
      state.country = data;
    },
    SET_FULL_NAME: (state, data) => {
      state.fullName = data;
    },
    SET_PHONE: (state, data) => {
      state.phone = data;
    },
    SET_UID: (state, data) => {
      state.userId = data;
    },
  },
  actions: {
    addDeliveryAddress: async ({ state }) => {
      await feathersClient.service("/api/v1/deliveryaddress").create(
        {
          addressLine1: state.addressLine1,
          addressLine2: state.addressLine2,
          city: state.city,
          state: state.stt,
          zipCode: state.zipCode,
          country: state.country,
          fullName: state.fullName,
          phone: state.phone,
          userId: state.userId,
        },
        {}
      );
      getAllAddresses();
    },
    deleteDeliveryAddress: async ({ state}, id) => {
      const result = await feathersClient.service("/api/v1/deliveryaddress").remove(id, {});
      console.log("deleted : ", result);
      getAllAddresses();
    },
    getAllAddresses: async ({ store, commit }) => {
      console.log("ASD");
      const result = await feathersClient
        .service("/api/v1/deliveryaddress")
        .find({
          query: {
          },
        });
        console.log(result)
        for(let i=0; i<result.data.length; i++) {
          if (result.data[i].showAddress === true) {
            console.log("&& -> ", result.data[i]);
            commit("storeAddresses", result.data);
            commit("SET_ADDRL1", result.data[i].addressLine1);
            commit("SET_ADDRL2", result.data[i].addressLine2);
            commit("SET_ZIPCODE", result.data[i].zipCode);
            commit("SET_CITY", result.data[i].city);
            break;
          }
        }
    },
    setSelectedDetails: async ({ store, commit }, payload) => {
      // console.log(payload);
      commit("SET_ADDRL1", payload.addressLine1);
      commit("SET_ADDRL2", payload.addressLine2);
      commit("SET_ZIPCODE", payload.zipCode);
      commit("SET_CITY", payload.city);
    },
  },
};
