import Vue from "vue";
import Vuex from "vuex";

import feathersClient from "../../../feathers-client";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
	customerDetails: '',
    itemsInWarehouse: []
  },
  getters: {},
  mutations: {
	SET_ITEMS_IN_WAREHOUSE(state, val){
		state.itemsInWarehouse = val
	},
	SET_CUSTOMER_DETAILS(state, val) {
		state.customerDetails = val
	}
  },
  actions: {
 getCustomerDetails: async ({commit,state},payload) => {
	const res = await feathersClient.service("/api/v1/users").find(payload)
	await res.data.sort(sortAccordingToCustomerId)
	function sortAccordingToCustomerId(user1, user2) {
		if(user1 && user1.loggedInCustomerDetails.length>0 && user2 && user2.loggedInCustomerDetails.length>0)
		   return user1.loggedInCustomerDetails[0].id - user2.loggedInCustomerDetails[0].id
	}
	commit("SET_CUSTOMER_DETAILS", res.data)
 },
 getWarehouseItemsInUsersSuite: async({commit, state}, payload) => {
	const items = await feathersClient.service('/api/v1/userbinitems').find({
		query:{
			userId: payload.id,
			$notConsolidated: true
		}
	})
	commit('SET_ITEMS_IN_WAREHOUSE', items.data)
 },
}
};
