import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import store from '../store'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import yicroutes from './routes/yicroutes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // { path: '/', redirect: { name: 'dashboard-ecommerce' } },
    { path: '/', redirect: { name: 'homepage' } },
    ...apps,
    ...yicroutes,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, _, next) => {
  let isLoggedIn = router.app.$store.getters['login/isAuthenticated']
  let role = router.app.$store.getters['login/role'];
  if (!isLoggedIn) {
    await router.app.$store.dispatch('login/loginUserWithJwt');
    isLoggedIn = router.app.$store.getters['login/isAuthenticated'];
    role = router.app.$store.getters['login/role'];
  }
  if (!to.meta.authRequired && role!= 'SUPERADMIN' ) {
    return next();
  }
  console.log("role ", role)
  // const isLoggedIn = true
 
    if (!isLoggedIn && to.meta.authRequired) {
      return next({ name: 'auth-login', params:{redirectTo:to.path} })
    }
    if(!isLoggedIn){
      return next({name: 'auth-login'})
    }
  
  if (isLoggedIn && to.name=='auth-login'){
    return next({name:'homepage'})
  }
  if (isLoggedIn && to.meta.adminPage && role === 'SUPERADMIN'){
    console.log("role",role)
    return next()
  }
  if (isLoggedIn && !to.meta.adminPage && role === 'OPERATOR'){
    return next({name: 'adminDashboard'})
  }
  if (isLoggedIn && to.meta.adminPage && role === 'ENDUSER'){
    return next({name: 'homepage'})
  }
  if (isLoggedIn && !to.meta.adminPage && role === 'SUPERADMIN'){
    return next({name: 'adminDashboard'})
  }
  if(to.params.redirect){
    return next({path: to.params.redirectTo})
  }
  return next()
})
// router.beforeEach((to, _, next) => {
//   let token = localStorage.getItem('feathers-jwt')
//   const isLoggedIn = router.app.$store.getters['login/isAuthenticated']
//   if(to.name != 'auth-login' && !token) {
//     return next({ name: 'auth-login' })
//   }else {  return next() }
//   // return next()
// })

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
