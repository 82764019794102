import feathersClient from '../../../feathers-client'

export default {
  namespaced: true,
  state: {
    searchResList : [],
    totalSerachRes : 0,
    additionalCharges:[],
    packageId:null,
    Items:[]

  },
  mutations: {
    SET_ITEMS: (state,val) =>{
      state.Items = val
    },
    setSearchRes : (state,result) =>{
      state.searchResList = result.data;
      state.totalSerachRes = result.total;
    },
    setAdditionalCharges :(state,payload) =>{
      console.log("setAdditionalCharges is called"+JSON.stringify(payload))
      state.additionalCharges = payload.additionalCharges;
    }
  },
  actions: {
    loadSearchRes : async({dispatch,state,commit},payload) =>{
      payload["$specialSearch"] = true;
      payload["fields"] = ['users']
      console.log("loadSearchRes payload value"+JSON.stringify(payload));
      var res = await feathersClient.service("api/v1/suit-addresses").find({query:payload})
      console.log("res of loadSearchRes =="+JSON.stringify(res));
      commit("setSearchRes",res)
    },
    addNewCharge : async({dispatch,state,commit},payload) =>{
      var newChargeObj = payload.newChargeObj;
      newChargeObj["shippingpackageId"] = payload.packageId;
      const res = await await feathersClient.service('api/v1/additionalcharges').create(newChargeObj)
      // state.packageId = payload.packageId;
      dispatch("fetchAddtionalCharges",payload.packageId)
      // const result = await feathersClient.service('/api/v1/shippingpackage').find({
      //   id: payload.packageId
      // })
      // const packageResult = await feathersClient.service('/api/v1/shippingpackage').patch(payload.packageId, {
      //   finalPrice: (result.data[0].finalPrice/100 + payload.newChargeObj.amount) * 100
      // }, {})
    },
    fetchAddtionalCharges: async({dispatch,state,commit},packageId) =>{
      var res = await feathersClient.service("api/v1/additionalcharges").find({query:{shippingpackageId:packageId}})
      console.log("res of loadSearchRes =="+JSON.stringify(res));
      commit("setAdditionalCharges",{additionalCharges:res.data})
    },
    getItems: async({state,commit},payload) =>{
      const res = await feathersClient.service('api/v1/userbinitems').find({
        query:{
          shippingpackageId: payload
        }
      })
      commit('SET_ITEMS',res.data)
    },
    updatePrice: async({state, commit}, payload) => {
      const res = await feathersClient.service('/api/v1/shippingpackages').find({
        id: payload.id
      })
      await feathersClient.service('/api/v1/shippingpackages').patch(payload.id, {
        finalPrice: (res.data[0].finalPrice/100 + payload.discount) * 100
      })
    }
  }
}
