import { STS } from 'aws-sdk';
import { max } from 'vee-validate/dist/rules';
import feathersClient from '../../../feathers-client'
export default {
  namespaced: true,
  state: {
    enteredWeight: 0,
    itemType: '',
    length: 0,
    width: 0,
    height: 0,
    volumePricingSelected: false,
    projectedPrice: {},
    country: '',
    shippingPartners: null,
  },
  getters: {

  },
  mutations: {
    SET_WEIGHT(state, val) {
      state.enteredWeight = val;
    },
    SET_VOLUME_DIMENSIONS(state, volume) {
      state.length = volume.length;
      state.width = volume.width;
      state.height = volume.height;
    },
    SET_VOLUME_PRICING_SELECTED(state, val) {
      state.volumePricingSelected = val;
    },
    SET_PROJECTED_PRICE(state, val) {
      const x = state.projectedPrice
      x[`${val.id}`] = val.shippingPrice;
      state.projectedPrice = x;
    },
    SET_COUNTRY(state, val) {
      state.country = val;
    },
    SET_ITEMTYPE(state, val) {
      state.itemType = val;
    },
    SET_PARTNERS(state, val) {
      state.shippingPartners = val;
    }

  },
  actions: {
    calculateShippingPrice: async ({ commit, state }, payload) => {
      const volume = state.length * state.width * state.height;
      const volumetricWeight = volume / 5000;
      const finalWeight = Math.ceil(Math.max(volumetricWeight, state.enteredWeight));
      // const volumetricWeight = volume / 5;
      // const finalWeight = Math.max(volumetricWeight, state.enteredWeight);
      // console.log(shippingpartnerId, volumetricWeight, state.enteredWeight, finalWeight, state.country.toLowerCase(), state.itemType.toLowerCase())
      const { shippingPartners } = state;
      if (shippingPartners) {
        for (let i = 0; i < shippingPartners.length; i++) {
          const id = shippingPartners[i].id;
          const priceResult = await feathersClient.service('/api/v1/shippingprices').find({
            query: {
              weightRangeMin: {
                $lte: finalWeight
              },
              weightRangeMax: {
                $gte: finalWeight
              },
              country: payload.country,
              itemType: state.itemType.toLowerCase(),
              shippingpartnerId: id
            }
          });
          let shippingPrice = 0;
          if (priceResult.data.length) {
            const unitPrice = priceResult.data[0].price;
            shippingPrice = unitPrice * finalWeight;
          }
          commit('SET_PROJECTED_PRICE', { id, shippingPrice });
        }
      }
    },

    //Fetch all shipping partners:
    fetchShippingPartners: async ({ commit, state }) => {
      const res = await feathersClient.service('/api/v1/shippingpartners').find({
        query: {}
      });
      console.log(res);
      commit('SET_PARTNERS', res.data);
    }
  },
}