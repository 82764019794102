import feathers from "@feathersjs/feathers";
import Vue from "vue";
import Vuex from "vuex";

import feathersClient from "../../../feathers-client";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    items: [],
    allItems: [],
    currentPage: 1,
    selectedItem: null,
    totalItems: 0,
    consolidate: [],
    allPackages: [], 
    qrCodeImageUrl: "",
    selectedAddressId: "",
    selectedAddress: "",
    returnItemId: 0,
    value: "",
    itemId: "",
    selectedItemsList: [],
    returnedItemList: [],
    priceValidation: 0,
  },
  mutations: {
    SET_ITEMS(state, val) {
      state.items = val;
    },
    SET_TOTAL_ITEMS(state, val) {
      state.totalItems = val;
    },
    SET_SELECTED_ITEM(state, item) {
      state.selectedItem = item;
      if (state.selectedItem.productpictures.length === 0) {
        state.selectedItem.productpictures.push({ url: "https://bitsofco.de/content/images/2018/12/broken-1.png" }
        );
        state.selectedItem.productpictures.push({ url: "https://bitsofco.de/content/images/2018/12/broken-1.png" }
        );
        state.selectedItem.productpictures.push({ url: "https://bitsofco.de/content/images/2018/12/broken-1.png" }
        );
      }
    },
    SET_CONSOLIDATE_ITEMS(state, selectedItems) {
      state.consolidate = selectedItems;
    },
    SET_SELECTED_ADDRESS_ID(state, val) {
      state.selectedAddressId = val;
    },
    SET_ADDRESS(state, val) {
      state.selectedAddress = val;
    },
    SET_ITEM_ID(state, val) {
      state.itemId = val;
    },
    SET_RETURNED_ITEM_ID(state, val) {
      state.returnItemId = val;
    },
    SET_ALL_ITEMS(state, val) {
      state.allItems = val;
    },
    SET_SELECTED_ITEM_LIST(state, val) {
      let selectedIndex = state.selectedItemsList.findIndex(
        (item) => item.id === val.id
      );
      if (selectedIndex > -1) {
        state.selectedItemsList.splice(selectedIndex, 1);
      } else {
        state.selectedItemsList.push(val);
      }
    },
    SET_RETURNED_ITEMS(state, val) {
      state.returnedItemList = val;
    },
    SET_PRICE_VALIDATION(state, val) {
      state.priceValidation = parseInt(val);
    },
  },
  actions: {
    showUserItems: async ({ state, commit }, payload) => {
      const result = await feathersClient.service("/api/v1/userbinitems").find({
        query: {
          $limit: payload.pageSize,
          $skip: (payload.currentPage - 1) * payload.pageSize,
          $notConsolidated: true,
          $sort: { whenArrived: -1 },
        },
      });
      if (state.consolidate.length > 0) {
        let selectedItems = state.consolidate.map((item) => item.id);
        result.data.map((item) => {
          if (selectedItems.includes(item.id)) {
            item.consolidate = true;
          }
        });
      }

      commit("SET_ITEMS", result.data);
      commit("SET_TOTAL_ITEMS", result.total);
      if (payload.currentPage == 1 && payload.index == 0) {
        const allResult = await feathersClient
          .service("/api/v1/userbinitems")
          .find({
            query: {
              $limit: state.totalItems,
              $notConsolidated: true,
              $sort: { whenArrived: -1 },
            },
          });
        commit("SET_ALL_ITEMS", allResult.data);
      }
    },
    addConsolidatedItems: async ({ state, commit }) => {
      await feathersClient.service("/api/v1/shippingpackage").create({
        date: new Date().toJSON().slice(0, 10).replace(/-/g, "/"),
        numberOfItems: state.consolidate.length,
        consolidatedItems: state.consolidate,
        deliveryaddressId: state.selectedAddressId,
        paymentStatus: "Initiated",
        shippingStatus: "Not Initiated",
        isCancelled: false
      });
    },
    updateValue: async ({ state }, payload) => {
      await feathersClient.service("/api/v1/userbinitems").patch(state.itemId, {
        value: payload.newVal,
      });
    },
    loadSelectedItem: async ({ commit }, id) => {
      const result = await feathersClient.service("/api/v1/userbinitems").find({
        query: {
          id: id,
        },
      });
      console.log("loaded selected item : ", result);
      commit("SET_SELECTED_ITEM", result.data[0]);
      commit("SET_RETURNED_ITEM_ID", id);
    },
    showItemsToBeReturned: async ({ state, commit }, payload) => {
      const result = await feathersClient.service("api/v1/userbinitems").find({
        query: {
          $limit: payload.pageSize,
          $skip: (payload.currentPage - 1) * payload.pageSize,
          $returned: true,
          $sort: { returnRequestedDate: -1 },
        },
      });
      result.data.map(
        (item) =>
        (item.displayStatus =
          item.returnStatus === null ? item.abandonStatus : item.returnStatus)
      );
      console.log("get returned items : ", result.data);
      commit("SET_RETURNED_ITEMS", result.data);
    },
    getPriceValidation: async ({ state, commit }) => {
      const result = await feathersClient.service('api/v1/adminsystemconfigs').find({
      })

      commit("SET_PRICE_VALIDATION", result.data[0].maxPackageValue)
    },
    updateReturnStatus: async ({ state, commit }, payload) => {
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      const formattedToday = yyyy + '-' + mm + '-' + dd;
      await feathersClient.service("/api/v1/userbinitems").patch(payload.id, {
        returnStatus: "Return Initiated",
        returnRequestedDate: formattedToday,
        reason: "____"
      });
    },
  },
};
