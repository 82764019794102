import Vue from "vue";
import Vuex from "vuex";
import feathersClient from "../../../feathers-client";
Vue.use(Vuex);

export default {
  namespaced: true,
  state:{
    adminSystemConfig:[],
    totalSystemConfig:0


  },
  getters:{},
  mutations: {
    allSystemConfig : (state,result) =>{
      state.adminSystemConfig = result.data,
      state.totalSystemConfig = result.total
    },


  },
  actions:{
    addAdminSystemConfig:async ({dispatch,state, commit},payload) =>{
      try {
        var response = await feathersClient.service('api/v1/adminsystemconfigs').create(payload)
        console.log("new systemconfig res"+JSON.stringify(response))
        dispatch("fetchAllSystemConfigs")
      } catch (e) {
        console.log("error occured while creating categories"+JSON.stringify(e))
        }
      },
      fetchAllSystemConfigs : async({store,commit}) =>{
        console.log("fetchAllSystemConfigs are called")
        //console.log("skip and limit values =="+JSON.stringify(payload));
        const result = await feathersClient
          .service("/api/v1/adminsystemconfigs")
          .find({
            query: {
              $sort:{
                updatedAt:-1
              }
            },
          });
        console.log("fetchAllSystemConfigs response =="+JSON.stringify(result));
        commit("allSystemConfig", result);
      },
      deleteSystemConfig:async({dispatch,state,commit},id) =>{

        const result = await feathersClient.service("/api/v1/adminsystemconfigs").remove(id, {});
        console.log("deleted : ", result);
        dispatch("fetchAllSystemConfigs")
      },
      editSystemConfig:async({dispatch,state,commit},payload) =>{
        var id = payload.id;
        delete payload["id"]
        const result = await feathersClient.service("/api/v1/adminsystemconfigs").patch(id,payload);
        console.log("updatedRes of systemConfig : ", result);
        dispatch("fetchAllSystemConfigs")
      }
    }
}
