export default [
	{
	  path: '/privacy-policy',
	  name: 'privacyPolicy',
	  component: () => import('@/yicpages/privacyPolicy/privacyPolicy.vue'),
	  meta: {
		layout: 'full',
	  },
	}
  ]
  