export default [
    {
        path: '/shipping-cost-calculator',
        name: 'shipping-cost-calculator',
        component: () => import('@/yicpages/ShippingCostCalculator/ShippingCostCalculator.vue'),
        meta: {
            layout: 'full',
        },
    },


]
