import feathersClient from '../../../feathers-client';
// import ShoppingCartItems from '../storeShoppingCartItems';
// import { useStore } from 'vuex';

export default {
    namespaced: true,
    state: {
        productCategories: null,
        productCategoriesForShippingPackage: new Map(),
        selectedCategory: null,
        selectedCategoryForShippingPackage: new Map(),
        products: new Map(),
        product: null,//used in productDescription page
        allProducts: null,
        allVariants: null,
        productVariants: null,//used in productDescription page
        tempVariant: new Map(),
        // tempVariant: {},
        subCategories: new Map(),
        subCategoriesForPackage: new Map(),
        selectedSubCategory: null,
        selectedSubCategoryForPackage: new Map(),
        currentPage: 1,
        productsForShippingPackage: new Map(),
        allProductsForShippingPackage: new Map(),
        variantsForShippingPackage: new Map(),
        productCategoriesWithTitle: new Map(),
        selectedCategoryProducts: [],
        selectedCategoryVariants: [],
        similarCategoryProducts: [],
        productIds: [],
        productIdsForAllCategories: {}
    },
    getters: {
    },
    mutations: {
        SET_PRODUCT_CATEGORIES(state, val) {
            state.productCategories = val;
        },
        SET_PRODUCT_CATEGORIES_FOR_SHIPPING_PACKAGE(state, val) {
            state.productCategoriesForShippingPackage.set(val.shippingPackageId, val.products);
            state.productCategoriesForShippingPackage = new Map(state.productCategoriesForShippingPackage)
        },
        SET_SELECTED_CATEGORY(state, val) {
            state.selectedCategory = val;
        },
        SET_SELECTED_CATEGORY_FOR_SHIPPING_PACKAGE(state, val) {
            state.selectedCategoryForShippingPackage.set(val.shippingPackageId, val.data);
            state.selectedCategoryForShippingPackage = new Map(state.selectedCategoryForShippingPackage)
        },
        SET_PRODUCTS(state, val) {
            state.products.set(val.categoryName, val.products)
            state.products = new Map(state.products)
        },
        SET_PRODUCT(state, val) {
            state.product = val;
        },
        SET_PRODUCT_VARIANTS(state, val) {
            state.productVariants = val;
        },
        SET_TEMP_VARIANT(state, val) {
            // if(state.tempVariant.hasOwnProperty(val.categoryName)){
            //     if(state.tempVariant[val.categoryName].hasOwnProperty(val.variant.productId)){
            //         state.tempVariant[val.categoryName][val.variant.productId].push(val.variant)
            //     }
            //     else{
            //         state.tempVariant[val.categoryName][val.variant.productId] = val.variant
            //     }
            // }
            // else{
            //     // state.tempVariant[val.categoryName] = []
            //     state.tempVariant[val.categoryName] = val.variant
            // }
            state.tempVariant.set(val.categoryName, val.variant)
            state.tempVariant = new Map(state.tempVariant)
            // if (!state.tempVariant[val.categoryName]) {
            //     state.tempVariant[val.categoryName] = {}; // Initialize the category object
            // }
            // if (!state.tempVariant[val.categoryName][val.variant.productId]) {
            //     state.tempVariant[val.categoryName][val.variant.productId] = []; // Initialize the product array
            // }
            
            // Now, you can safely push the value into the array
            // state.tempVariant[val.categoryName] = (val.variant);
        },
        SET_ALL_PRODUCTS(state, val) {
            state.allProducts = val;
        },
        SET_ALL_VARIANTS(state, val) {
            state.allVariants = val;
        },
        SET_SUB_CATEGORIES(state, val) {
            state.subCategories.set(val.category, val.subCategories)
            state.subCategories = new Map(state.subCategories)
        },
        SET_SUB_CATEGORIES_FOR_PACKAGE(state, val) {
            state.subCategoriesForPackage.set(val.shippingPackageId, val.data);
            state.subCategoriesForPackage = new Map(state.subCategoriesForPackage)
        },
        SET_SELECTED_SUB_CATEGORY(state, val) {
            state.selectedSubCategory = val;
        },
        SET_CURRENT_PAGE(state, val) {
            state.currentPage = val;
        },
        SET_PRODUCTS_FOR_SHIPPING_PACKAGE(state, val) {
            state.productsForShippingPackage.set(val.shippingPackageId, val.products)
            state.productsForShippingPackage = new Map(state.productsForShippingPackage)
        },
        SET_ALL_PRODUCTS_FOR_SHIPPING_PACKAGE(state, val) {
            state.allProductsForShippingPackage.set(val.shippingPackageId, val.products)
            state.allProductsForShippingPackage = new Map(state.allProductsForShippingPackage)
        },
        SET_ALL_VARIANTS_FOR_SHIPPING_PACKAGE(state, val){
            state.variantsForShippingPackage.set(val.shippingPackageId, val.variants)
            state.variantsForShippingPackage = new Map(state.variantsForShippingPackage)
        },
        SET_PRODUCT_CATEGORIES_WITH_TITLE(state, val) {
            state.productCategoriesWithTitle.set(val.categoryName, val.title)
            state.productCategoriesWithTitle = new Map(state.productCategoriesWithTitle)
        },
        SET_SELECTED_CATEGORY_PRODUCTS(state, val){
            state.selectedCategoryProducts = val
        },
        SET_SELECTED_CATEGORY_VARIANTS(state, val) {
            state.selectedCategoryVariants = val
        },
        SET_SIMILAR_CATEGORY_PRODUCTS(state, val) {
            state.similarCategoryProducts = val
        },
    },
    actions: {
        // ====================================== Product Category ======================================

        fetchProductCategories: async ({ commit, state }) => {
            const result = await feathersClient.service('/api/v1/productcategories').find({
                query: {
                    $sort:{
                        createdAt: 1
                    }
                }
            });
            commit('SET_PRODUCT_CATEGORIES', result.data);
            // if (!state.selectedCategory) commit('SET_SELECTED_CATEGORY', result.data[0]);
            // if (!state.selectedCategory) commit('SET_SELECTED_CATEGORY', result.data[0]);
        },
        fetchProductCategoriesForShippingPackage: async ({ commit, state }, payload) => {
            const result = await feathersClient.service('/api/v1/productcategories').find({
                query: {}
            });
            
            commit('SET_PRODUCT_CATEGORIES_FOR_SHIPPING_PACKAGE', {shippingPackageId: payload.shippingPackageId, products: result.data});
        },

        setSelectedCategory: async ({ commit, state }, { category }) => {
            await commit('SET_SELECTED_CATEGORY', category);
        },
        setSelectedCategoryForPackage: async ({ commit, state }, payload) => {
            await commit('SET_SELECTED_CATEGORY_FOR_SHIPPING_PACKAGE', {shippingPackageId: payload.shippingPackageId, data: payload.category});
        },

        setCurrentPage: async ({ commit, state }, { pageNum }) => {
            // console.log(pageNum);
            await commit('SET_CURRENT_PAGE', pageNum);
        },

        populateSelectedCategoryFromId: async ({ commit, state }, { id }) => {
            if (id == 'null') {
                id = state.productCategories[0].id;
            }
            const result = await feathersClient.service('/api/v1/productcategories').find({
                query: {
                    id
                }
            });
            commit('SET_SELECTED_CATEGORY', result.data[0]);
        },
        populateSelectedCategoryFromIdForShippingPackage: async ({ commit, state }, payload) => {
            if (payload.id == 'null') {
                console.log("product catgeory", state.productCategoriesForShippingPackage.get(payload.shippingPackageId))
                payload.id = state.productCategoriesForShippingPackage.get(payload.shippingPackageId)[0].id;
            }
            const result = await feathersClient.service('/api/v1/productcategories').find({
                query: {
                    id: payload.id
                }
            });
            commit('SET_SELECTED_CATEGORY_FOR_SHIPPING_PACKAGE', {shippingPackageId: payload.shippingPackageId, data: result.data[0]});
        },


        //  ====================================== SUBCATEGORY  ======================================

        fetchSubCategories: async ({ commit, state }) => {
            const promise = state.productCategories.map(async (cat) => {
                const result = await feathersClient.service('/api/v1/storesubcategories').find({
                    query: {
                        productcategoryId: cat.id
                    }
                });
                result.data.map(sCat => {
                    sCat['showSelectedCategory'] = false
                })
                commit('SET_SUB_CATEGORIES', {category: cat.name, subCategories: result.data});
            })
            await Promise.all(promise);
        },
        setSelectedSubCategory: async ({ commit, state }, payload) => {
            // console.log('bhavya2',subCategoryId);
            commit('SET_SELECTED_SUB_CATEGORY', payload && payload.subCategory ? payload.subCategory : null);
        },
        //  ======================================= Product ======================================= 
        fetchProducts: async ({ commit, state }, payload) => {
            if(!payload){
                return;
            }
            // if (!payload && !payload.selectedCategory) return;
            var query = {}
            if(!payload.selectedCategory){
                query={
                    productcategoryId: state.selectedCategory.id,
                    $limit: 1000,
                    status: 'active'
                }
            }
            else if(payload.selectedSubCategories){
                let subCategories = payload.selectedSubCategories.map(scat => {
                    return scat.id
                })
                console.log("subCategories", subCategories);
                query={
                    productcategoryId: payload.selectedCategory.id,
                    $limit: 1000,
                    status: 'active'
                }
                query['storesubcategoryId'] = {
                    $in: subCategories
                }
            }
            else if(!payload.selectedSubCategory && !payload.selectedSubCategories){
                query={
                    productcategoryId: payload.selectedCategory.id,
                    $limit: 1000,
                    status: 'active'
                }
            }
            else{
                query={
                    productcategoryId: payload.selectedCategory.id,
                    storesubcategoryId: payload.selectedSubCategory.id,
                    $limit: 1000,
                    status: 'active'
                }
            }
            const result = await feathersClient.service('/api/v1/products').find({
                query
            });
            commit('SET_PRODUCTS', {categoryName: payload.selectedCategory.name, products: result.data});
        },

        // Fetching All products..
        fetchAllProducts: async ({ commit, state }, payload) => {
            const result = await feathersClient.service('/api/v1/products').find({
                query: {
                    $limit: 1000,
                    $sort: {
                        SKU: 1,
                    }
                },
            });
            commit('SET_ALL_PRODUCTS', result.data);
        },

        fetchAllProductsForShippingPackage: async ({ commit, state }, payload) => {
            const result = await feathersClient.service('/api/v1/products').find({
                query: {
                    $limit: 1000,
                    $sort: {
                        SKU: 1,
                    }
                },
            });

            if(payload && payload.shippingPackageId){
                const yourCartItems = rootGetters['storeShoppingCartItems/getYourCartItems']
                result.data.map(product => {
                    const isProductInCart = yourCartItems.has(payload.shippingPackageId) && yourCartItems.get(payload.shippingPackageId).length>0 ? yourCartItems.get(payload.shippingPackageId).find(item => item.productId == product.id) : 0
                    if(isProductInCart){
                        product.quantity = isProductInCart.quantity
                    }
                    else{
                        product.quantity = 0;
                    }
                })
            }
            commit('SET_ALL_PRODUCTS_FOR_SHIPPING_PACKAGE', {shippingPackageId: payload.shippingPackageId, products: result.data});
        },

        // Specific Product fetch..
        fetchProduct: async ({ commit, state }, payload) => {
            commit('SET_PRODUCT', null);
            let query = {}
            query = {
                query: {
                    id: payload.id,
                    status: 'active'
                }
            }
            if(payload.fetchVariants)
              query["query"]["fields"] = ["fetchVariants"]
            console.log("product query for fetchign variants", query);
            const result = await feathersClient.service('/api/v1/products').find(query);
            commit('SET_PRODUCT', result.data[0]);
        },

        //  ======================================= Variant ======================================= 
        // Fetching All variants..
        getTempVariant: async ({ commit, state }, payload) => {
            let query = {}
            query = {
                query: {
                    $sort: {
                        priceOriginal: 1,
                    }
                }
            }
            if(payload && payload.limit){
                query['query']['$limit'] = payload.limit
            }
            if(payload && payload.selectedSubCategory && payload.selectedSubCategory!=null){
                query['query']['$selectedSubCategoryId'] = payload.selectedSubCategory.id
            }
            if(payload && payload.minPrice && payload.maxPrice){
                query['query']['priceOriginal'] = {
                    $gte: payload.minPrice,
                    $lte: payload.maxPrice
                }
            }
            if(payload && !payload.filterAccToQuantity){
                query['query']['inventoryQuantity'] = {
                    $gte: 0
                }
            }
            if(payload && payload.filterAccToQuantity){
                query['query']['inventoryQuantity'] = {
                    $gt: 0
                }
            }
            if(payload && payload.selectedSubCategories){
                query['query']['$selectedSubCategories'] = payload.selectedSubCategories
            }
            if(payload && payload.sortByPrice) {
                query['query']['$sort'] = {
                    priceOriginal: -1
                }
            }

            query['query']['$selectedCategoryId'] = payload.selectedCategory.id
            // query['query']['$groupby'] = payload.selectedCategory.id
            const result = await feathersClient.service('/api/v1/variants').find(query);
            let obj = {};
            state.productIds = []
            let promise = result.map(res => {
                if(obj[res.products[0].id]){
                    obj[res.products[0].id].push(res)
                }
                else{
                    obj[res.products[0].id] = []
                    obj[res.products[0].id].push(res)
                }
                if(!state.productIds.includes(res.products[0].id)){
                    state.productIds.push(res.products[0].id)
                    if(!state.productIdsForAllCategories[payload.selectedCategory.name]){
                        state.productIdsForAllCategories[payload.selectedCategory.name] = []
                    }
                    if(state.productIdsForAllCategories[payload.selectedCategory.name] && !state.productIdsForAllCategories[payload.selectedCategory.name].includes(res.products[0].id)){
                        state.productIdsForAllCategories[payload.selectedCategory.name].push(res.products[0].id)
                    }
                }
            })
            await Promise.all(promise)
            commit('SET_TEMP_VARIANT', {categoryName: payload.selectedCategory.name, variant: obj});
            return result
        },

        fetchAllVariants: async ({ commit, state }) => {
            const result = await feathersClient.service('/api/v1/variants').find({
                query: {
                    $limit: 1000,
                    $sort: {
                        SKU: 1,
                    }
                }
            });
            commit('SET_ALL_VARIANTS', result.data);
        },

        // Specific Variant of a Product fetch..
        fetchProductVariants: async ({ commit, state }, _id) => {
            const result = await feathersClient.service('/api/v1/variants').find({
                query: {
                    productId: _id,
                    status: 'active'
                }
            });
            commit('SET_PRODUCT_VARIANTS', result.data);
        },
        getVendorName: async({commit, state}, payload) => {
            const res = await feathersClient.service('/api/v1/vendors').find({
                query:{
                    id: payload.id
                }
            })
            console.log("response in diw", res);
            return res.data[0].name
        },
        getVariantsForShippingPackage: async({commit, state, rootGetters}, payload) => {
            let query = {}
            query = {
                query:{
                    status: 'active',
                }
            }
            if(payload && payload.weight && payload.weight>0){
                query['query']['shippingWeight'] = {
                    $lte: payload.weight
                }
            }
            if(state.selectedCategoryForShippingPackage.has(payload.shippingPackageId) && state.selectedCategoryForShippingPackage.get(payload.shippingPackageId).id){
                query['query']['$selectedCategoryId'] = state.selectedCategoryForShippingPackage.get(payload.shippingPackageId).id
                const res = await feathersClient.service('api/v1/variants').find(query)

                const yourCartItems = rootGetters['storeShoppingCartItems/getYourCartItems']
                res.map(variant => {
                    const isVariantInCart = yourCartItems.has(payload.shippingPackageId) && yourCartItems.get(payload.shippingPackageId).length>0 ? yourCartItems.get(payload.shippingPackageId).find(item => item.variantId == variant.id) : 0
                    if(isVariantInCart){
                        variant.quantity = isVariantInCart.quantity
                    }
                    else{
                        variant.quantity = 0;
                    }
                })
                commit('SET_ALL_VARIANTS_FOR_SHIPPING_PACKAGE', {shippingPackageId: payload.shippingPackageId, variants: res})
            }
        },
        getCategoriesWithTitle: async({commit, state}) => {
            const res = await feathersClient.service('api/v1/productcategories').find({})
            res.data.map(cat => {
                commit("SET_PRODUCT_CATEGORIES_WITH_TITLE", {categoryName: cat.name, title: cat.title})
            })
        },
        getProductVariant: async({commit, state}, id) => {
            const res = await feathersClient.service('/api/v1/variants').find({
                query:{
                    $limit: 1000,
                    productId: id
                }
            })
            return res.data
        },
        setSelectedCategoryProducts: async({commit, state}, payload) => {
            commit('SET_SELECTED_CATEGORY', payload.selectedCategory)
        },
        setProductsForSimilarCategories: async({commit, state}, payload) => {
            let arr = []
            const promise = payload.similarCategories.map(async category => {
                const res = await feathersClient.service('/api/v1/products').find({
                    query:{
                        $limit: 1000,
                        productcategoryId: category.id
                    }
                })
                arr.push(res.data)
            })
            await Promise.all(promise);
            let flattenedArray = [].concat(...arr);
            commit('SET_SIMILAR_CATEGORY_PRODUCTS', flattenedArray)
        }
    },
}