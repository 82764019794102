export default [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/yicpages/dashboard/Dashboard.vue"),
    meta: {
      layout: "full",
      authRequired: true,
    },
    children: [
      {
        path: '/dashboard/dashboardLanding',
        name: 'dashboardLanding',
        component: () => import('@/yicpages/dashboard/DashboardLanding.vue'),
        meta: {
          layout: 'full',
          authRequired: true,
        },
      },
      {
        path: '/dashboard/account-settings',
        name: 'account-settings',
        component: () => import('@/yicpages/dashboard/DashboardMain.vue'),
        meta: {
          layout: 'full',
          authRequired: true,
        }
      },
      {
        path: '/dashboard/myOrders',
        name: 'myOrders',
        component: () => import('@/yicpages/dashboard/MyOrders/MyOrders.vue'),
        meta: {
          layout: 'full',
          authRequired: true,
        },
        children: [
          {
            path: '/dashboard/myOrders/productsInWarehouse',
            name: 'productsInWarehouse',
            component: () => import('@/yicpages/dashboard/MyOrders/ProductsInWarehouse.vue'),
            meta: {
              layout: 'full',
              authRequired: true,
            },
          },
          {
            path: '/dashboard/myOrders/consolidationStatus',
            name: 'consolidationStatus',
            component: () => import('@/yicpages/dashboard/MyOrders/ConsolidationStatus.vue'),
            meta: {
              layout: 'full',
              authRequired: true,
            },
          },
          {
            path: '/dashboard/myOrders/readyForShipping',
            name: 'readyForShipping',
            component: () => import('@/yicpages/dashboard/MyOrders/ReadyForShipping.vue'),
            meta: {
              layout: 'full',
              authRequired: true,
            },
          },
          {
            path: '/dashboard/myOrders/PackageTracking',
            name: 'packageTracking',
            component: () => import('@/yicpages/dashboard/MyOrders/PackageTracking.vue'),
            meta: {
              layout: 'full',
              authRequired: true,
            },
          },
          {
            path: '/dashboard/myOrders/PackagesDelivered',
            name: 'packagesDelivered',
            component: () => import('@/yicpages/dashboard/MyOrders/PackagesDelivered.vue'),
            meta: {
              layout: 'full',
              authRequired: true,
            },
          }
        ]
      },
      {
        path: '/dashboard/pickupDetails',
        name: 'pickupDetails',
        component: () => import('@/yicpages/dashboard/PickupScreens/PickUpDetails.vue'),
        meta: {
          layout: 'full',
          authRequired: true
        }
      },
      {
        path: '/dashboard/pickup',
        name: 'pickup',
        component: () => import('@/yicpages/dashboard/PickupScreens/PickupItems.vue'),
        meta: {
          layout: 'full',
          authRequired: true
        }
      },
      {
        path: '/dashboard/return-abandon',
        name: 'return-abandon',
        component: () => import('@/yicpages/dashboard/ReturnAndAbandonItems.vue'),
        meta: {
          layout: 'full',
          authRequired: true
        }
      },
      {
        path: '/dashboard/payment-completed',
        name: 'paymentsCompleted',
        component: () => import('@/yicpages/dashboard/PaymentCompletedOrders.vue'),
        meta: {
          layout: 'full',
          authRequired: true
        }
      }
    ]
  },
  {
    path: "/view-item/:id",
    name: "view-item",
    component: () => import("@/yicpages/dashboard/DashboardItem.vue"),
    meta: {
      layout: "full",
      authRequired: true,
    },
  },
  {
    path: "/readyforpayment/:id",
    name: "test",
    component: () =>
      import("@/yicpages/dashboard/MyOrders/ConsolidationStatus2.vue"),
    meta: {
      layout: "full",
      authRequired: true,
    },
  },
  {
    path: "/reset-password/:id",
    name: "test",
    component: () =>
      import("@/yicpages/dashboard/MyOrders/ConsolidationStatus2.vue"),
    meta: {
      layout: "full",
      authRequired: true,
    },
  },
];