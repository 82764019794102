import Vue from "vue";
import Vuex from "vuex";
import feathersClient from "../../../feathers-client";
Vue.use(Vuex);

export default {
  namespaced: true,
  state:{
    errors: '',
    success: false,
    carousel: [],
    totalCarousel: 0


  },
  getters:{},
  mutations: {
    
    SET_ERROR(state,val){
      state.errors = val
    },
    SET_SUCCESS(state,val){
      state.success = val
    },
    SET_CAROUSELS(state, val){
      state.carousel = val.data;
      state.totalCarousel = val.total;
    }

  },
  actions:{
    addCarousel: async ({ commit, state }, payload) => {
      try{
		const result = await feathersClient.service('/api/v1/homepagecarousel').create({
		  title: payload.title,
		  description: payload.description,
		  imageUrl: payload.imageURL
		});
    commit('SET_ERROR','')
		commit('SET_SUCCESS',true)
  }catch(e){
			commit('SET_ERROR',e)

  }
	  },
    fetchAllCarousel : async({store,commit},payload) =>{
      console.log("skip and limit values =="+JSON.stringify(payload));
      const result = await feathersClient
        .service("/api/v1/homepagecarousel")
        .find({
          query: {
            $skip:payload.skip,
            $limit:payload.limit,
            $sort:{
              updatedAt:-1
            }
          },
        });
      console.log("fetchAllCategories response =="+JSON.stringify(result));
      commit("SET_CAROUSELS", result);
    },
    deleteCarousel:async({dispatch,state,commit},id) =>{
      var query = {
        skip:0,
        limit:10
      }
      const result = await feathersClient.service("/api/v1/homepagecarousel").remove(id, {});
      console.log("deleted : ", result);
      dispatch("fetchAllCategories",query)
    },
    }
}
