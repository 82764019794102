import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import customerDetails from './yicmodules/customerDetails'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import register from './yicmodules/registration'
import login from './yicmodules/login'
import basicprofilemodule from './yicmodules/basicprofilemodule'
import adminCarousel from './yicmodules/adminCarousel'
import adminStore from './yicmodules/adminStore'
import adminTestimonials from './yicmodules/adminTestimonials'
import shippingcalculator from './yicmodules/shippingcalculator'
import testimonials from './yicmodules/testimonial'
import homePageCarousel from './yicmodules/HomePageCarousel'
import editedProfile from './yicmodules/basicprofilemodule'
import userItems from './yicmodules/itemsinusersuite'
import address from './yicmodules/addresses'
import shippingPackages from './yicmodules/shippingpackages'
import emailVerification from './yicmodules/emailVerification'
import senderDetails from './yicmodules/senderDetails'
import warehouseAddress from './yicmodules/warehouseAddress'
import userDetails from './yicmodules/UserDetails'
import deliveryDestination from './yicmodules/deliveryAddresses'
import editDeliveryAddress from './yicmodules/editDeliveryAddress'
import shippingPartner from './yicmodules/shippingPartners'
import uploadToS3 from './yicmodules/uploadToS3'
import uploadProfileToS3 from './yicmodules/uploadProfileToS3'
import payments from './yicmodules/payments'
import notifications from './yicmodules/notification'
import resetPass from './yicmodules/resetPwd'
import adminsystemConfig from './yicmodules/adminSystemConfig'
import routesStore from './yicmodules/routing'
import tracking from './yicmodules/trackingPackage'
import storeProducts from './yicmodules/storeProducts/'
import abandons from './yicmodules/abandons'
import pickUpStore from './yicmodules/pickup'
import storeShoppingCartItems from './yicmodules/storeShoppingCartItems'
import additionalcharges from './yicmodules/additionalCharges'
import storePayments from './yicmodules/storePayment'
import productCategories from './yicmodules/categories'
import categories from './yicmodules/categories'
import merchants from './yicmodules/merchants'
import adminCreateOrder from './yicmodules/adminCreateOrder'
import vendorOrder from './yicmodules/vendorOrders'
import consolidateItems from './yicmodules/consolidationStatus'
import countriesSelection from './yicmodules/countriesSelection'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    register,
    tracking,
    customerDetails,
    adminTestimonials,
    adminCarousel,
    notifications,
    basicprofilemodule,
    login,
    resetPass,
    shippingcalculator,
    adminStore,
    testimonials,
    payments,
    homePageCarousel,
    editedProfile,
    adminCreateOrder,
    userItems,
    address,
    shippingPackages,
    emailVerification,
    senderDetails,
    warehouseAddress,
    userDetails,
    deliveryDestination,
    editDeliveryAddress,
    shippingPartner,
    uploadToS3,
    uploadProfileToS3,
    adminsystemConfig,
    additionalcharges,
    routesStore,
    pickUpStore,
    categories,
    merchants,
    'app-ecommerce': ecommerceStoreModule,

    abandons,
    storeProducts,
    storePayments,
    storeShoppingCartItems,
    vendorOrder,
    consolidateItems,
    countriesSelection

  },
  //plugins: [...servicePlugins],
  //FIXME: We need to use persistent storage
  // currently on page refresh, store is getting reset
  strict: process.env.DEV,
})
