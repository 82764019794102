import feathersClient from '../../../feathers-client'
export default {
  namespaced: true,
  state: {
    errors: '',
    success: false,
    testimonials: [],
    totaltestimonials: 0
},
  getters: {

  },
  mutations: {
    SET_ERROR(state,val){
      state.errors = val
    },
    SET_SUCCESS(state,val){
      state.success = val
    },
    SET_TESTIMONIALS(state, val){
      state.testimonials = val.data;
      state.totaltestimonials = val.total;
    }
  },
  actions: {
    addTestimonials: async ({ commit, state },payload) => {
      try{
      const result = await feathersClient.service('/api/v1/testimonial').create({
      title: payload.title,
		  description: payload.description,
		  imageUrl: payload.imageURL,
      rating: payload.rating
      });
      commit('SET_ERROR','')
		  commit('SET_SUCCESS',true)
    }catch(e){
			commit('SET_ERROR',e)
    }
    },
    fetchAlltestimonials : async({store,commit},payload) =>{
      console.log("skip and limit values =="+JSON.stringify(payload));
      const result = await feathersClient
        .service("/api/v1/testimonial")
        .find({
          query: {
            $skip:payload.skip,
            $limit:payload.limit,
            $sort:{
              updatedAt:-1
            }
          },
        });
      console.log("fetchAllCategories response =="+JSON.stringify(result));
      commit("SET_TESTIMONIALS", result);
    },
    deleteTestimonials:async({dispatch,state,commit},id) =>{
      var query = {
        skip:0,
        limit:10
      }
      const result = await feathersClient.service("/api/v1/testimonial").remove(id, {});
      console.log("deleted : ", result);
      dispatch("fetchAllCategories",query)
    },
  },
}