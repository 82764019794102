import feathersClient from '../../../feathers-client'
export default {
  namespaced: true,
  state: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: '',
    password: '',
    registrationInProgress: false,
    registeredUser: null,
    verifying: true,
    userAlreadyExists: false
  },
  getters: {

  },
  mutations: {
    SET_REGISTRATION_IN_PROGRESS(state, val) {
      state.registrationInProgress = val;
    },
    SET_DEMOGRAPHICS(state, demo) {
      state.firstName = demo.firstName;
      state.lastName = demo.lastName;
      state.email = demo.email;
      state.country = demo.country;
      state.phone = demo.phone;
    },
    SET_PASSWORD(state, val) {
      state.password = val;
    },
    SET_REGISTERED_USER(state, val) {
      state.registeredUser = val;
    },
    SET_VERIFYING(state, val) {
      state.verifying = val;
    },
    // SET_FIRSTNAME(state, val) {
    //   state.firstName = val;
    // },
    SET_EXISTING_USER(state, val){
      state.userAlreadyExists = val;
    }
  },
  actions: {
    registerUser: async ({ commit, state }) => {
      commit('SET_REGISTRATION_IN_PROGRESS', true);
      commit("SET_EXISTING_USER");
      try {
        const registeredUser = await feathersClient.service('api/v1/users').create({
          firstName: state.firstName,
          lastName: state.lastName,
          email: state.email,
          phone: state.phone,
          country: state.country,
          password: state.password,
        });
        commit('SET_REGISTERED_USER', registeredUser);
        commit("SET_REGISTRATION_IN_PROGRESS", false);
        commit("SET_EXISTING_USER", false);
      } catch (err) {
        console.log("->->", err.errors[0].message);
        if(err.errors[0].message==="email must be unique"){
          commit('SET_EXISTING_USER', true);
        }
        commit("SET_REGISTRATION_IN_PROGRESS", false);
      }
    },
    // setfirstname: async ({ commit }, payload) => {
    //   commit('SET_FIRSTNAME', payload)
    // },
    verifyUser: async({commit, state}) => {
      // commit('SET_VERIFYING', true);
      // try {
      //   const result = await feathersClient.service('api/v1/authmanagement').create({
      //   });
      // }
    },
  },
}