export default [
    {
        path: '/aboutus',
        name: 'aboutUs',
        component: () => import('@/yicpages/AboutUs/aboutUs.vue'),
        meta: {
            layout: 'full',
        },
    },
    
]