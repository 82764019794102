import { validatorUrlValidator } from "@/@core/utils/validations/validators";
import feathersClient from "../../../feathers-client";
export default {
  namespaced: true,
  state: {
    S3Cred: null,
    profilePicUrl: null,
  },
  getters: {},
  mutations: {
    setS3Cred: function (state, val) {
      state.S3Cred = val;
    },
    setUrl: function (state, val) {
      state.profilePicUrl = val;
    },
  },
  actions: {
    getS3Cred: async ({ state, commit }, payload) => {
      const result = await feathersClient
        .service("/api/v1/s3uploadcredentials")
        .find({
          query: {
            fileName: payload,
          },
        });
      if (result.data.length) {
        console.log("### : ", result.data);
        commit("setS3Cred", result.data[0]);
        console.log("Obtained result in S3");
      } else {
        console.log("Error in S3");
      }
    },

    async setProfilePicToDatabase({ commit, state }, payload) {
      const result = await feathersClient.service("/api/v1/users").patch(
        null,
        {
          profileURL: payload,
        },
        {}
      );
      console.log("After updating Profile : ", result);
    },

    async getProfilePicFromDB({ commit, state }) {
      const result = await feathersClient.service("/api/v1/users").find({});
      console.log("After getting Profile : ", result);
      commit("setUrl", result.data[0].profileURL);
    },
  },
};
