export default [
    {
        path: '/home',
        name: 'homepage',
        component: () => import('@/yicpages/homepage/HomePage.vue'),
        meta: {
            layout: 'full',
            
        },
    },
]