import feathersClient from '../../../feathers-client'
import { v4 as uuid } from 'uuid';

export default {
    namespaced: true,
    state: {
        vendorOrderItems: [],
        vendors: []
    },
    getters: {

    },
    mutations: {
        SET_VENDOR_ORDER_ITEMS(state, val){
            state.vendorOrderItems = val
        },
        SET_VENDORS(state, val){
            state.vendors = val;
        }
    },
    actions: {
        getVendorOrderItems: async ({ state, commit }, id) => {
            const vendors = await feathersClient.service('/api/v1/vendororders').find({
                query:{
                    $sort: {
                        createdAt: -1
                    },
                    fields: ['orderItems']
                }
            })
            commit('SET_VENDOR_ORDER_ITEMS', vendors.data)
        },
        editOrderStatus: async({ state, commit }, payload) => {
            const res = await feathersClient.service('/api/v1/vendororders').patch(payload.id, {
                OrderStatus: 'Delivered'
            })
        },
        printLabel: async({ state, commit }, payload) => {
            // const res = await feathersClient.service('/api/v1/printlabel').create({
            //     data: payload
            // })
        }
    },
}


