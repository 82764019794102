import Vue from "vue";
import Vuex from "vuex";
import feathersClient from "../../../feathers-client";

Vue.use(Vuex);
export default {
  namespaced: true,
  state: {
    categories:[],
    totalCategories :0

  },
  getters: {},
  mutations: {
    allCategories :(state,result) =>{
      state.categories = result.data;
      state.totalCategories = result.total;

    }
  },
  actions: {
    addNewCategories: async ({ dispatch,state, commit },payload) =>{
      console.log("new Categories name in category store.js"+JSON.stringify(payload));
      try {
        var response = await feathersClient.service('api/v1/categories').create({
          name:payload
        })
        console.log("new categories res"+JSON.stringify(response))
        var query={
          skip:0,
          limit:10
        }
        dispatch("fetchAllCategories",query)
      } catch (e) {
        console.log("error occured while creating categories"+JSON.stringify(e))
      }

    },
    fetchAllCategories : async({store,commit},payload) =>{
      console.log("skip and limit values =="+JSON.stringify(payload));
      const result = await feathersClient
        .service("/api/v1/categories")
        .find({
          query: {
            $skip:payload.skip,
            $limit:payload.limit,
            $sort:{
              updatedAt:-1
            }
          },
        });
      console.log("fetchAllCategories response =="+JSON.stringify(result));
      commit("allCategories", result);
    },
    deleteCategory:async({dispatch,state,commit},id) =>{
      var query = {
        skip:0,
        limit:10
      }
      const result = await feathersClient.service("/api/v1/categories").remove(id, {});
      console.log("deleted : ", result);
      dispatch("fetchAllCategories",query)
    },
    editCategory:async({dispatch,state,commit},payload) =>{
      var query = {
        skip:0,
        limit:10
      }
      const result = await feathersClient.service("/api/v1/categories").patch(payload.id, {name:payload.name});
      console.log("updatedRes : ", result);
      dispatch("fetchAllCategories",query)
    }

  }
}
