import { validatorUrlValidator } from "@/@core/utils/validations/validators";
import feathersClient from "../../../feathers-client";
export default {
  namespaced: true,
  state: {
    // s3Image : null,
    S3Cred: null,
    aadharFrontUrl: null,
    aadharBackUrl: null,
  },
  getters: {},
  mutations: {
    setS3Cred: function (state, val) {
      state.S3Cred = val;
    },
    setUrls: function (state, val) {
      console.log("RU : ", val.url);
      if (val.f == "frontAC") {
        if (val.url) {
          state.aadharFrontUrl = val.url;
        } 
        // else {
        //   state.aadharFrontUrl =
        //     "../../assets/yicimages/dashboard/no-image-found.png";
        // }
      } else if (val.f == "backAC") {
        if (val.url) {
          state.aadharBackUrl = val.url;
        } 
        // else {
        //   state.aadharBackUrl =
        //     "https://bitsofco.de/content/images/2018/12/broken-1.png";
        // }
      } else {
        console.log("###");
      }
    },
  },
  actions: {
    getS3Cred: async ({ state, commit }, payload) => {
      const result = await feathersClient
        .service("/api/v1/s3uploadcredentials")
        .find({
          query: {
            fileName: payload,
          },
        });
      if (result.data.length) {
        commit("setS3Cred", result.data[0]);
      } else {
        console.log("Error in S3");
      }
      //   return result;
    },

    async getAadharFromDatabase({ commit, state }) {
      console.log("GetAadharFromData");
      const result = await feathersClient
        .service("/api/v1/senderaddresses")
        .find({
          query: {},
        });
      console.log("GetAadharFromDatabase -> ", result.data[0]);
      const x = {
        url: result.data[0].aadharFrontUrl,
        f: "frontAC"
      };
      const y = {
        url: result.data[0].aadharBackUrl,
        f: "backAC",
      };
      commit('setUrls', x);
      commit('setUrls', y);
      // commit("setDetails", result.data[0]);
    },
  },
};
