import feathersClient from "../../../feathers-client";

export default {
  namespaced: true,
  state: {
    virtualAddress: "",
    suitNumber: "",
    warehouseComponents:  [],
    phoneNumber: ''
  },
  getters: {

  },
  mutations: {
    setWarehouseAddress(state, addr){
        state.virtualAddress = addr;
    },
    setSuitNumber(state, no){
        state.suitNumber = no;
    },
    setWarehouseComponents(state, components){
      state.warehouseComponents = [...components];
    },
    SET_PHONE_NUMBER(state, val){
      const sub = val.split('#');
      state.phoneNumber = sub[4].substring(0,13);
    }
  },
  actions: {
    async getWarehouseAddress({ commit },payload) {

      const result = await feathersClient
        .service("/api/v1/suit-addresses")
        .find({
          query: {
            userId: payload,
          },
        });
      console.log(result.data[0].warehouseAddress);
      const components = result.data[0].warehouseAddress.split('#');
      commit("setWarehouseComponents", components);
      commit("setWarehouseAddress", result.data[0].warehouseAddress);
      commit("setSuitNumber", result.data[0].suitNumber);
      const res = await feathersClient.service('/api/v1/adminsystemconfigs').find({})
      commit("SET_PHONE_NUMBER", res.data[0].warehouseAddress)
    },
  },
};
