export default [
	{
		path: '/plans',
		name: 'plans',
		component: () => import('@/yicpages/services/services.vue'),
		meta: {
		  layout: 'full',
		},
	  }
  ]
  