export default [
    {
        path: '/howyicworks',
        name: 'howyicworks',
        component: () => import('@/yicpages/HowItWorks/HowItWorks.vue'),
        meta: {
            layout: 'full',
        },
    },
    
]