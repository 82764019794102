/*
    Razorpay Test card numbers

    Mastercard
    5267 3181 8797 5449 

    Visa
    4111 1111 1111 1111

*/

import feathersClient from '../../../feathers-client'
import { v4 as uuid } from 'uuid';

export default {
    namespaced: true,
    state: {
        shoppingCartItems: null,
        finalPrice: null,
        orderId: null,
        razorpayKeyId: null,
        razorpayOrderId: null,
        paymentDone: false,
        priceCalculated: false,///fix
        suitAddress: null,
        paymentGatewayOption: '',
        ccAvenueResponse: '',
        paymentStatus: '',
        paymentMode: '',
        yourCartItems: new Map(),
        finalProducts: [],
        progressBarValue: 0,
        remainingWeight: 0,
        max: 0,
        extraWeight: 0,
        shippingPartnersDetails: new Map(),
        totalPrice: new Map(),
        cartTotal: 0,
        finalProductsInPackage: new Map(),
        activeTab: 0
    },
    getters: {
        getYourCartItems(state) {
            return state.yourCartItems
        },
    },
    mutations: {
        SET_SHOPPING_CART_ITEMS(state, val) {
            state.shoppingCartItems = val;
        },
        SET_FINAL_PRICE(state, val) {
            state.finalPrice = val;
        },
        SET_RAZORPAY_KEY_ID(state, val) {
            state.razorpayKeyId = val
        },
        SET_RAZORPAY_ORDER_ID(state, val) {
            state.razorpayOrderId = val
        },
        SET_PAYMENT_DONE(state, val) {
            state.paymentDone = val
        },
        SET_PAYMENT_STATUS(state, val) {
            state.paymentstatus = val
        },
        SET_ORDER_ID(state, val) {
            state.orderId = val
        },
        SET_SUIT_ADDRESS(state, val) {
            state.suitAddress = val;
        },
        SET_CCAVENUE_RESPONSE_DATA(state, val) {
            state.ccAvenueResponse = val
        },
        SET_PAYMENT_GATEWAY_OPTION(state, val){
            state.paymentGatewayOption = val
        },
        SET_PAYMENT_MODE(state, val) {
            state.paymentMode = val
        },
        SET_YOUR_CART_ITEMS(state, val){
            if(val.data.length>0){
                state.yourCartItems.set(val.shippingPackageId, val.data)
                state.yourCartItems = new Map(state.yourCartItems)
            }
            else if(val.data.length == 0 && state.yourCartItems.has(val.shippingPackageId)){
                state.yourCartItems.delete(val.shippingPackageId)
                state.yourCartItems = new Map(state.yourCartItems)
            }
        },
        SET_FINAL_PRODUCTS(state, val){
            state.finalProducts = val
        },
        SET_FINAL_PRODUCTS_IN_PACKAGE(state, val){
            state.finalProductsInPackage.set(val.shippingPackageId, val.products)
            state.finalProductsInPackage = new Map(state.finalProductsInPackage)
        },
        SET_MAX_VALUE(state, val) {
            state.max = val
        },
        SET_PROGRESS_BAR_VALUE(state, val) {
            state.progressBarValue = val
        },
        SET_EXTRA_WEIGHT(state, val) {
            state.extraWeight = val;
        },
        SET_SHIPPING_PARTNERS_DETAILS(state, val) {
            state.shippingPartnersDetails.set(val.shippingPackageId, val.shippingPartnerDetails)
            state.shippingPartnersDetails = new Map(state.shippingPartnersDetails)
        },
        SET_TOTAL_PRICE(state, val) {
            state.totalPrice.set(val.shippingPackageId, val.totalPrice)
            state.totalPrice = new Map(state.totalPrice)
        },
        SET_CART_TOTAL(state, val) {
            state.cartTotal = val;
        },
        SET_ACTIVE_TAB(state, val){
            state.activeTab = val
        }
    },
    actions: {
        // Shopping Cart Cost of all products
        calculateCost: async ({ commit, state }) => {
            let p = 0;
            if (state.shoppingCartItems) {
                state.shoppingCartItems.map(async item => {
                    p += item.quantity * (item.variant[0].priceOffer || item.variant[0].priceOriginal);
                    return item;
                })
            }
            commit('SET_FINAL_PRICE', p);
        },

        // fetching shopping cart items
        setShoppingCartItems: async ({ commit, state }, { userId }) => {
            const anonymousUserId = localStorage.getItem("anonymousUserId");
            let result = null;
            if (userId) {//user is logged in
                result = await feathersClient.service('/api/v1/shoppingcartitems').find({
                    query: {
                        userId,
                        $sort: {//sort by created by asc $created
                            createdAt: 1
                        },
                        $notConsolidated: true
                    }
                });
            } else if (anonymousUserId) {//local id
                result = await feathersClient.service('/api/v1/shoppingcartitems').find({
                    query: {
                        anonymousUserId,
                        $sort: {//sort by created by asc $created
                            createdAt: 1
                        },
                        $notConsolidated: true
                    }
                });
            }
            commit('SET_SHOPPING_CART_ITEMS', result ? result.data : null);
        },

        // adding product to cart
        addItemToCart: async ({ state, commit }, { quan, productId, variantId, anonymousUserId, userId }) => {
            // let userId = null;
            // if item is already in cart update its quantity!
            if (anonymousUserId) {
                // item in cart by anonymous id
                let result = await feathersClient.service('/api/v1/shoppingcartitems').find({
                    query: {
                        productId,
                        variantId,
                        anonymousUserId,
                    }
                });
                if (result.data.length>0) {
                    const id_ = result.data[0].id;
                    await feathersClient.service('/api/v1/shoppingcartitems').patch(id_, {
                        // query:{
                            quantity: result.data[0].quantity + quan
                        // }
                    }, {});
                }
                // adding a new item in cart!
                else{
                    const res = await feathersClient.service('/api/v1/shoppingcartitems').create({
                        productId,
                        quantity: quan,
                        variantId,
                        anonymousUserId: (anonymousUserId ? anonymousUserId : ''),
                        userId
                    });
                    if (!res.userId && !anonymousUserId) {//user id and (anonymousGiven) are both null
                        await localStorage.setItem('anonymousUserId', res.anonymousUserId);
                    }
                }
            } 
            else if (userId) {
                // item in cart by user id
                let result = await feathersClient.service('/api/v1/shoppingcartitems').find({
                    query: {
                        productId,
                        variantId,
                        userId,
                    }
                });
                if (result.data.length>0) {
                    const id_ = result.data[0].id;
                    await feathersClient.service('/api/v1/shoppingcartitems').patch(id_, {
                        // query:{
                            quantity: result.data[0].quantity + quan
                        // }
                    }, {});
                }
                // adding a new item in cart!
                else{
                    const res = await feathersClient.service('/api/v1/shoppingcartitems').create({
                        productId,
                        quantity: quan,
                        variantId,
                        anonymousUserId: (anonymousUserId ? anonymousUserId : ''),
                        userId
                    });
                    if (!res.userId && !anonymousUserId) {//user id and (anonymousGiven) are both null
                        await localStorage.setItem('anonymousUserId', res.anonymousUserId);
                    }
                }
            }
        },
        getItemInCart: async({commit, state}, payload) => {
            let query = {
                productId: payload.productId,
                variantId: payload.variantId,
            }
            if(payload.anonymousUserId){
                query['anonymousUserId'] = payload.anonymousUserId
            }
            else if(payload.userId){
                query['userId'] = payload.userId
            }
            const res = await feathersClient.service('api/v1/shoppingcartitems').find({
                query
            })
            return res
        },

        addItemToShippingPackage: async({state, commit}, payload) => {
            let result = await feathersClient.service('/api/v1/shoppingcartitems').find({
                query: {
                    productId: payload.productId,
                    variantId: payload.variantId,
                    userId: payload.userId,
                    shippingpackageId: payload.shippingPackageId,
                }
            });

            if(result.data.length>0 && payload.quantity>0){
                await feathersClient.service('/api/v1/shoppingcartitems').patch(result.data[0].id, {
                    quantity: payload.quantity,
                    value: payload.value * payload.quantity
                });
            }
            if(result.data.length == 0 && payload.quantity>0){
                        await feathersClient.service('/api/v1/shoppingcartitems').create({
                            productId: payload.productId,
                            quantity: payload.quantity,
                            variantId: payload.variantId,
                            anonymousUserId: '',
                            userId: payload.userId,
                            shippingpackageId: payload.shippingPackageId,
                            value: payload.value * payload.quantity
                        });
            }
            
            if(payload.quantity == 0){
                const id_ = result.data[0].id;
                await feathersClient.service('/api/v1/shoppingcartitems').remove(id_);
            }
        },

        getItemsInShippingPackage: async({state, commit}, payload) => {
            let query = {}
            if(payload.getQuantity){
                query = {
                    shippingpackageId: payload.shippingPackageId,
                    productId: payload.productId,
                    variantId: payload.variantId,
                    $sort: {
                        createdAt: -1
                    }
                }
            }
            else{
                query = {
                    shippingpackageId: payload.shippingPackageId,
                    $sort: {
                        createdAt: -1
                    }
                }
            }
            const res = await feathersClient.service("/api/v1/shoppingcartitems").find({
                query
            })
            if(!payload.getQuantity){
               let obj = {
                'shippingPackageId': payload.shippingPackageId,
                'data': res.data
               }
               commit('SET_YOUR_CART_ITEMS', obj)
               let weight = 0;
               let totalPrice = 0;
                res.data.map(item => {
                    weight = weight + item.shippingWeight*item.quantity
                    totalPrice = totalPrice + (item.variant[0].priceOffer ? item.variant[0].priceOffer*item.quantity : item.variant[0].priceOriginal*item.quantity)
                })
                commit('SET_TOTAL_PRICE', {shippingPackageId: payload.shippingPackageId, totalPrice: totalPrice})
            }
            return res
          },
        

        // updating qunatity of product present in cart
        updateQuantity: async ({ state, commit }, { id, quan }) => {
            await feathersClient.service('/api/v1/shoppingcartitems').patch(id, {
                quantity: quan
            })
        },

        // deleting product from cart
        deleteFromCart: async ({ state, commit }, id) => {
            await feathersClient.service('/api/v1/shoppingcartitems').remove(id)
        },


        /**************** order creation  ********************/
        createOrder: async ({ commit, state }) => { 
            const paymentOption = await feathersClient.service('api/v1/paymentgatewayselection').find({})
            commit('SET_PAYMENT_GATEWAY_OPTION', paymentOption.paymentgatewayOption)
            commit('SET_PAYMENT_MODE', paymentOption.paymentMode)
            // Razorpay
            if (state.paymentGatewayOption === 'Razorpay') {
                const newOrder = await feathersClient.service('/api/v1/orders').create({
                    orderId: uuid(),
                    status: 'razorpayOrderCreationStart',
                    finalPrice: state.finalPrice * 100,// * 100 to convert ruppee into paisa
                    // belongs to user thing -> after create hooks
                })
                // if order generated successfully
                if (newOrder.razorpayOrderId) {
                    commit('SET_ORDER_ID', newOrder.id)
                    // commit('SET_FINAL_PRICE', newOrder.finalPrice)
                    commit('SET_RAZORPAY_ORDER_ID', newOrder.razorpayOrderId)
                    commit("SET_RAZORPAY_KEY_ID", newOrder.razorpayKeyId)
                }
            } else if (state.paymentGatewayOption === 'CCAvenue') {
                const newOrder = await feathersClient.service('/api/v1/orders').create({
                    orderId: uuid(),
                    status: 'initiated',
                    finalPrice: state.finalPrice,// * 100 to convert ruppee into paisa
                    // belongs to user thing -> after create hooks
                })
                commit('SET_ORDER_ID', newOrder.id)
                commit('SET_PAYMENT_STATUS', newOrder.status)
                const res = await feathersClient.service('api/v1/storeccavenuepayment').create({
                    amount: state.finalPrice,
                    orderId: newOrder.id
                })
                commit('SET_CCAVENUE_RESPONSE_DATA', res)
                console.log("ORDER CREATED", res);
            }
        },
        // order creation for direct purchase
        createOrderForDirectPurchase: async({commit, state}, payload) => {
            const paymentOption = await feathersClient.service('api/v1/paymentgatewayselection').find({})
            commit('SET_PAYMENT_GATEWAY_OPTION', paymentOption.paymentgatewayOption)
            commit('SET_PAYMENT_MODE', paymentOption.paymentMode)
            // Razorpay
            if (state.paymentGatewayOption === 'Razorpay') {
                const newOrder = await feathersClient.service('/api/v1/orders').create({
                    orderId: uuid(),
                    status: 'razorpayOrderCreationStart',
                    finalPrice: payload.price * 100,// * 100 to convert ruppee into paisa
                    // belongs to user thing -> after create hooks
                })
                // if order generated successfully
                if (newOrder.razorpayOrderId) {
                    commit('SET_ORDER_ID', newOrder.id)
                    // commit('SET_FINAL_PRICE', newOrder.finalPrice)
                    commit('SET_RAZORPAY_ORDER_ID', newOrder.razorpayOrderId)
                    commit("SET_RAZORPAY_KEY_ID", newOrder.razorpayKeyId)
                }
            } else if (state.paymentGatewayOption === 'CCAvenue') {
                const newOrder = await feathersClient.service('/api/v1/orders').create({
                    orderId: uuid(),
                    status: 'initiated',
                    finalPrice: payload.price,// * 100 to convert ruppee into paisa
                    // belongs to user thing -> after create hooks
                })
                commit('SET_ORDER_ID', newOrder.id)
                commit('SET_PAYMENT_STATUS', newOrder.status)
                const res = await feathersClient.service('api/v1/storeccavenuepayment').create({
                    amount: payload.price,
                    orderId: newOrder.id
                })
                commit('SET_CCAVENUE_RESPONSE_DATA', res)
                console.log("ORDER CREATED", res);
            }
        },
        /* razorpay order generation */
        /*================================== Payment Success ==================================*/
        createRazorpayPaymentsSuccess: async ({ commit, state }, payload) => {
            const res = await feathersClient.service('api/v1/storerazorpaysuccesspayments').create({
                razorpayOrderId: payload.razorpayOrderId,
                razorpayPaymentId: payload.paymentId,
                razorpaySignature: payload.signature,
                orderId: state.orderId
            })
            if (res.paymentStatus == 'Done') {
                commit('SET_PAYMENT_DONE', true)
                await feathersClient.service('/api/v1/orders').patch(state.orderId, {
                    razorpayPaymentId: payload.paymentId
                })
            } else return;


            // creating purchased items from cart items
            state.shoppingCartItems.map(async item => {
                // creating purchased items
                await feathersClient.service('/api/v1/purchaseditems').create({
                    orderId: state.orderId,
                    variantId: item.variantId,
                    quantity: item.quantity,
                    price: (item.variant[0].priceOffer || item.variant[0].priceOriginal)
                });

                // finding category of product
                const productCategory = await feathersClient.service('/api/v1/productcategories').find({
                    query: {
                        id: item.product[0].productcategoryId,
                    }
                });

                const { variantId } = item;
                const t = await feathersClient.service('/api/v1/variants').find({
                    query: {
                        id: variantId
                    },
                })
                const productId = t.data[0].productId;
                const l = await feathersClient.service('/api/v1/products').find({
                    query: {
                        id: productId
                    },
                });
                const vendorId = l.data[0].vendorId;

                //updating inventory quantity for the variant
                await feathersClient.service('/api/v1/variants').patch(item.variantId, {
                    inventoryQuantity: (t.data[0].inventoryQuantity - item.quantity)
                })



                // adding items to userBinItems
                const r = await feathersClient.service('/api/v1/userbinitems').create({
                    itemName: item.product[0].name,
                    itemCategory: productCategory.data[0].name,
                    whenArrived: Date.now(),
                    itemSourceType: 'store',
                    itemSource: 'store',
                    quantity: item.quantity,
                    value: (item.variant[0].priceOffer || item.variant[0].priceOriginal) * item.quantity,
                    description: item.product[0].description,
                    consolidate: false,
                    fulfilmentStatus: 'Pending',
                    packageStatus: 'not added',
                    variantId: item.variantId,
                    vendorId
                });
                //adding product images to userbin items
                //need to better implemenatation
                for (let i = 0; i < 3; i++) {
                    if (item.product[0].productImages[i]) {
                        const res = await feathersClient.service('/api/v1/userbinitemspicture').create({
                            url: item.product[0].productImages[i],
                            userbinitemId: r.id,
                        })
                    }
                }
                // deleting cart items
                await feathersClient.service('/api/v1/shoppingcartitems').remove(item.id)
            })
        },
        createRazorpayPaymentsSuccessForDirectPurchase: async({commit, state}, payload) => {
            const res = await feathersClient.service('api/v1/storerazorpaysuccesspayments').create({
                razorpayOrderId: payload.razorpayOrderId,
                razorpayPaymentId: payload.paymentId,
                razorpaySignature: payload.signature,
                orderId: state.orderId
            })
            if (res.paymentStatus == 'Done') {
                commit('SET_PAYMENT_DONE', true)
                await feathersClient.service('/api/v1/orders').patch(state.orderId, {
                    razorpayPaymentId: payload.paymentId
                })
            } else return;


            // creating purchased items from cart items
            // payload.product.map(async item => {
                // creating purchased items
                const productPrice = payload.variant.priceOffer ? payload.variant.priceOffer : payload.variant.priceOriginal 
                await feathersClient.service('/api/v1/purchaseditems').create({
                    orderId: state.orderId,
                    variantId: payload.variant.id,
                    quantity: payload.quantity,
                    price: productPrice * payload.quantity
                });

                // finding category of product
                const productCategory = await feathersClient.service('/api/v1/productcategories').find({
                    query: {
                        id: payload.variant.products[0].productcategoryId,
                    }
                });

                const variantId  = payload.variant.id;
                const t = await feathersClient.service('/api/v1/variants').find({
                    query: {
                        id: variantId
                    },
                })
                const productId = t.data[0].productId;
                const l = await feathersClient.service('/api/v1/products').find({
                    query: {
                        id: productId
                    },
                });
                const vendorId = l.data[0].vendorId;

                //updating inventory quantity for the variant
                await feathersClient.service('/api/v1/variants').patch(payload.variant.id, {
                    inventoryQuantity: (t.data[0].inventoryQuantity - payload.quantity)
                })



                // adding items to userBinItems
                const r = await feathersClient.service('/api/v1/userbinitems').create({
                    itemName: payload.variant.products[0].name,
                    itemCategory: productCategory.data[0].name,
                    whenArrived: Date.now(),
                    itemSourceType: 'store',
                    itemSource: 'store',
                    quantity: payload.quantity,
                    value: (payload.variant.priceOffer || payload.variant.priceOriginal) * payload.quantity,
                    description: payload.variant.products[0].description,
                    consolidate: false,
                    fulfilmentStatus: 'Pending',
                    packageStatus: 'not added',
                    variantId: payload.variant.id,
                    vendorId
                });
                //adding product images to userbin items
                //need to better implemenatation
                for (let i = 0; i < 3; i++) {
                    if (payload.variant.products[0].productImages[i]) {
                        const res = await feathersClient.service('/api/v1/userbinitemspicture').create({
                            url: payload.variant.products[0].productImages[i],
                            userbinitemId: r.id,
                        })
                    }
                }
                // deleting cart items
                // await feathersClient.service('/api/v1/shoppingcartitems').remove(item.id)
            // })
        },
        // fetching suit address
        fetchSuitAddress: async ({ commit, state }, { userId }) => {
            if (!userId) return;
            const r = await feathersClient.service('/api/v1/suit-addresses').find({
                query: {
                    userId
                }
            })
            commit('SET_SUIT_ADDRESS', r.data[0].warehouseAddress);
        },

        ccAvenuePaymentDoneHandler: async ({ commit, state }) => {
            // creating purchased items from cart items
            state.shoppingCartItems.map(async item => {
                // creating purchased items
                await feathersClient.service('/api/v1/purchaseditems').create({
                    orderId: state.orderId,
                    variantId: item.variantId,
                    quantity: item.quantity,
                    price: (item.variant[0].priceOffer || item.variant[0].priceOriginal)
                });

                // finding category of product
                const productCategory = await feathersClient.service('/api/v1/productcategories').find({
                    query: {
                        id: item.product[0].productcategoryId,
                    }
                });

                const { variantId } = item;
                const t = await feathersClient.service('/api/v1/variants').find({
                    query: {
                        id: variantId
                    },
                })
                const productId = t.data[0].productId;
                const l = await feathersClient.service('/api/v1/products').find({
                    query: {
                        id: productId
                    },
                });
                const vendorId = l.data[0].vendorId;

                //updating inventory quantity for the variant
                await feathersClient.service('/api/v1/variants').patch(item.variantId, {
                    inventoryQuantity: (t.data[0].inventoryQuantity - item.quantity)
                })



                // adding items to userBinItems
                const r = await feathersClient.service('/api/v1/userbinitems').create({
                    itemName: item.product[0].name,
                    itemCategory: productCategory.data[0].name,
                    whenArrived: Date.now(),
                    itemSourceType: 'store',
                    itemSource: 'store',
                    quantity: item.quantity,
                    value: (item.variant[0].priceOffer || item.variant[0].priceOriginal) * item.quantity,
                    description: item.product[0].description,
                    consolidate: false,
                    fulfilmentStatus: 'Pending',
                    packageStatus: 'not added',
                    variantId: item.variantId,
                    vendorId
                });
                //adding product images to userbin items
                //need to better implemenatation
                for (let i = 0; i < 3; i++) {
                    if (item.product[0].productImages[i]) {
                        const res = await feathersClient.service('/api/v1/userbinitemspicture').create({
                            url: item.product[0].productImages[i],
                            userbinitemId: r.id,
                        })
                    }
                }
                // deleting cart items
                await feathersClient.service('/api/v1/shoppingcartitems').remove(item.id)
            })
        },

        checkOrderStatus: async ({ commit, state }, { userId, orderId }) => {
            console.log("in order status");
            const res = await feathersClient.service('/api/v1/orders').find({
                query: {
                    id: orderId,
                    userId,
                }
            });
            console.log("in order status", res);

            if (res.data.length) {
                let val = false;
                if (res.data[0].status === "Done") val = true;
                commit('SET_ORDER_ID', res.data[0].id)
                commit('SET_PAYMENT_DONE', val);
                commit('SET_PAYMENT_STATUS', res.data[0].status)
            }
        },

        calculateFinalShippingPriceOfPackage: async({commit, state}, payload) => {
            let finalWeight = 0;
            if(payload.weight > payload.volumetricWeight){
                finalWeight = parseInt(Math.ceil(payload.weight))
            }
            else{
                finalWeight = parseInt(Math.ceil(payload.volumetricWeight))
            }
            const res = await feathersClient.service('api/v1/shippingprices').find({
                query: {
                    weightRangeMin: {
                      $lte: finalWeight
                    },
                    weightRangeMax: {
                      $gte: finalWeight
                    },
                    country: payload.country
                }
            })
            let obj = [];
            res.data.map(shippingPrice => {
                let temp = {}
                temp['id'] = shippingPrice.id
                temp['shippingPartnerName'] = shippingPrice.shippingPartner[0].shippingpartners
                temp['price'] = shippingPrice.price * finalWeight
                obj.push(temp);
            })
            commit('SET_SHIPPING_PARTNERS_DETAILS', {shippingPackageId: payload && payload.shippingPackageId ? payload.shippingPackageId : '', shippingPartnerDetails: obj});
        },
        setCartTotalInShippingPackage: async({commit, state}, payload) => {
            await this.feathersClient.service('/api/v1/shippingpackage').patch(payload.id, {
              cartTotal: payload.cartTotal,
              numberOfItems: payload.numberOfItems,
            //   weight: payload.weight,
              finalPrice: payload.finalPrice
            })
            commit('SET_CART_TOTAL', payload.cartTotal)
        },
        updateValue: async({commit, state}, payload) => {
            await feathersClient.service('/api/v1/shoppingcartitems').patch(payload.id, {
                value: payload.value
            })
        },

    }, 
} 


