import feathersClient from '../../../feathers-client'
export default {
	namespaced: true,
	state: {
		firstName: '',
		lastName: '',
		allOpsUsers:[],
		totalOpsUser:0,
		errors:''
	},
	getters: {

	},
	mutations: {
		SET_DETAILS(state, val) {
			state.firstName = val
		},
		allOpsUsers : (state,result) =>{
      state.allOpsUsers = result.data,
      state.totalOpsUser = result.total
    },
	SET_ERRORS(state, val) {
		console.log("set_errors method is called =="+JSON.stringify(val));
		state.errors = val
	},

	},
	actions: {
		getData: async ({ state, commit }) => {
			const result = await feathersClient.service('/api/v1/authentication').find({
				// email: this.userEmail,
				// password: this.password
			})
			commit('SET_DETAILS', result)
		},
		createOpsUser: async({dispatch,state,commit},payload) =>{
			payload["role"] = "OPERATOR";
			payload["isVerified"] = true;
			try{
				const res = await feathersClient.service('/api/v1/users').create(payload)
				console.log("response for ops user creation"+JSON.stringify(res));
				commit('SET_ERRORS','')
				var query={
          skip:0,
          limit:10
        }
        dispatch("fetchAllOpsUser",query)
			}catch(e){
				commit('SET_ERRORS',e.errors[0].message)

			}
			},
		fetchAllOpsUser: async({state,commit},payload) =>{
			const result = await feathersClient.service("/api/v1/users").find({
					query: {
						$skip:payload.skip,
						$limit:payload.limit,
						$sort:{
							updatedAt:-1
						},
						role:'OPERATOR'
					},
				});

			console.log("ops user list =="+JSON.stringify(result));
			commit("allOpsUsers", result);
		},
		blockOpsUser: async({state,commit,dispatch},id) =>{
			const res = await feathersClient.service("/api/v1/users").patch(id,{isblocked:true})
			console.log("isblocked response"+JSON.stringify(res))

		},
		unblockOpsUser: async({state,commit,dispatch},id) =>{
			const res = await feathersClient.service("/api/v1/users").patch(id,{isblocked:false})
			console.log("UnBlock response"+JSON.stringify(res))

		},
		deleteOpsUser:async({state,commit,dispatch},id) =>{
      const result = await feathersClient.service("/api/v1/users").remove(id, {});
      console.log("deleted : ", result);

		},
		updateOpsUser:async({state,commit,dispatch},payload) =>{
			var id = payload.id;
			delete payload.id;
			console.log("payload value in res"+JSON.stringify(payload))
			const res = await feathersClient.service("/api/v1/users").patch(id,payload)

		}
	},
}
