export default [
    {
        path: '/payment/:id',
        name: 'payment',
        component: () => import('@/yicpages/Payment/Payment.vue'),
        meta: {
            layout: 'full',
            authRequired: true,
        },
    },
    {
        path: '/payment/ccavenuecheckout/:encReq',
        name: 'ccAvenueCheckout',
        component: () => import('@/yicpages/Payment/CCAvenueCheckout.vue'),
        meta: {
            layout: 'full',
            authRequired: true,
        },
    },
    {
        path: '/payment/ccavenueresponse/',
        name: 'ccAvenueResponse',
        component: () => import('@/yicpages/Payment/CCAvenueResponse.vue'),
        meta: {
            layout: 'full',
            authRequired: true,
        },
    },
]