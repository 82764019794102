export default [
    {
        path: '/FAQ/',
        name: 'faq',
        component: () => import('@/yicpages/faq/Faq.vue'),
        meta: {
            layout: 'full',
        },
    },
    
]