import feathersClient from "../../../feathers-client";

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    setAbandon: async ({ state }, payload) => {
      console.log("setAbandon ** ", payload);
      const result = await feathersClient.service("/api/v1/userbinitems").patch(
        payload.itemID,
        {
          abandonStatus: "Abandonment Initiated",
          reason: payload.reason,
          isProhibited: payload.pc,
          abandonRequestedDate: payload.reqDate,
        },
        {}
      );
      console.log(result);
    },
  },
};
