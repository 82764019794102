import feathersClient from '../../../feathers-client'
export default {
  namespaced: true,
  state: {
    // Weight: '',
    // Price: '',
    // Temp: 0,
    // country: 'india',
	  shippingPartner: '',
	// shippingPartnerPrices: [],
    allShippingPartners:[],
    selectedPartnerPrices:[],
    selectedPartnerId:null,
    selectedShippingPartner: ''
  },
  getters: {

  },
  mutations: {
    allShippingPartners:(state,result) =>{
      state.allShippingPartners = result;
    },

    SET_SHIPPING_PARTNER_NAME(state,val){
      state.shippingPartner = val
    },
    SET_Prices(state,payload){
      //console.log("selectedpartners priceList =="+JSON.stringify(payload))
      state.selectedPartnerPrices = payload.shippingPrices;
    },
    SET_SHIPPING_PARTNER(state, val) {
      state.selectedShippingPartner = val
    }


  },
  actions: {

    fetchAllShippingPartners: async ({commit},payload) =>{
      var query = { query:{}}
      query["query"]["$distinct"] = "AllshippingPartners"
      query["query"]["fields"] = ['shippingPrice'];

      const res = await feathersClient.service('/api/v1/shippingpartners').find(query)
      console.log("shippingPartners =="+JSON.stringify(res))
      commit("allShippingPartners",res)
    },
    setShippingPartner: async ({ commit }, payload) => {
      console.log(payload)
      const res=await feathersClient.service('/api/v1/shippingpartners').find({
        query: {
          shippingpartners: payload.selectedPartner[0].shippingPartnerName,
        }
      })
      console.log(res)
      commit('SET_SHIPPING_PARTNER', payload.selectedPartner[0])
      commit('SET_SHIPPING_PARTNER_NAME',res.data[0].shippingpartners)
      await feathersClient.service('/api/v1/shippingpackage').patch(payload.id,{
        selectedShippingPartnerId: res.data[0].id,
        shippingPrice: payload.selectedPartner[0].price
      })
    },
    updatePrice: async({dispatch,state,commit},payload) =>{
      var res = await feathersClient.service('/api/v1/shippingprices').patch(payload.id,payload)
      console.log("updated price information =="+JSON.stringify(res));
      state.selectedPartnerId = payload.shippingpartnerId
      dispatch("fetchParticularPartnerPrices")
    },
    addNewShippingPartner: async ({dispatch,state,commit},payload) =>{
      console.log("new Shipping partner info =="+JSON.stringify(payload))
      await feathersClient.service('/api/v1/shippingpartners').create(payload)
      dispatch("fetchAllShippingPartners")

    },
    addNewShippingPrice: async ({dispatch,state,commit},payload) =>{
      await feathersClient.service('/api/v1/shippingprices').create(payload)
      state.selectedPartnerId = payload.shippingpartnerId
      dispatch("fetchParticularPartnerPrices")
    },
    fetchParticularPartnerPrices : async ({dispatch,state,commit},payload) =>{
      const res=await feathersClient.service('/api/v1/shippingprices').find({
        query: {
          shippingpartnerId: state.selectedPartnerId,
        }
      })
      console.log("particular  shippingPartner attached shippingPrice"+JSON.stringify(res))
      var obj = {
        shippingPrices:res.data
      }
      commit('SET_Prices',obj)
    },
    removeShippingPrice : async ({dispatch,state,commit},payload) =>{
      state.selectedPartnerId = payload.shippingPartnerId;
      const result = await feathersClient.service("/api/v1/shippingprices").remove(payload.id, {});
      dispatch("fetchParticularPartnerPrices")
      console.log("deleted : ", result);
    }
  },
}
