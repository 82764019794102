import { codeContextual } from '@/views/components/badge/code'
import feathersClient from '../../../feathers-client'

export default {
  namespaced: true,
  state: {
    addresses: [],
    id: '',
    addressToBeEdited: '',
    isNewAddressComp: false,
    addressLine1: '',
    addressLine2: '',
    city: '',
    state1: '',
    country: '',
    pinCode: '',
    select: 0,
    phone: '',
    fullName: '',
    pickedUpAddress: ''
  },
  mutations: {
    SET_ADDRESSES(state, val) {
      state.addresses = val
    },
    GET_SELECTED_ID(state, val) {
      state.id = val
    },
    UPDATE_ADDRESS_DETAILS(state) {
      state.addressToBeEdited = state.addresses.filter(particularAddress => particularAddress.id === state.id)
    },
    SET_ADDRESS_LINE_1(state, val) {
      if (this.isNewAddressComp === false) {
        state.addressToBeEdited[0].addressLine1 = val
      }
      else {
        state.addressLine1 = val
      }
    },
    SET_ADDRESS_LINE_2(state, val) {
      if (this.isNewAddressComp === false) {
        state.addressToBeEdited[0].addressLine2 = val
      }
      else {
        state.addressLine2 = val
      }
    },
    SET_CITY(state, val) {
      if (this.isNewAddressComp === false) {
        state.addressToBeEdited[0].city = val
      }
      else {
        state.city = val
      }
    },
    SET_STATE(state, val) {
      if (this.isNewAddressComp === false) {
        state.addressToBeEdited[0].state = val
      }
      else {
        state.state1 = val
      }
    },
    SET_ZIP_CODE(state, val) {
      if (this.isNewAddressComp === false) {
        state.addressToBeEdited[0].zipCode = val
      }
      else {
        state.pinCode = val
      }
    },
    SET_COUNTRY(state, val) {
      if (this.isNewAddressComp === false) {
        state.addressToBeEdited[0].country = val
      }
      else {
        state.country = val
      }
    },
    SET_CONTACT(state, val) {
      if (this.isNewAddressComp === false) {
        state.addressToBeEdited[0].phone = val
      }
      else {
        state.phone = val
      }
    },
    SET_FULLNAME(state, val) {
      if (this.isNewAddressComp === false) {
        state.addressToBeEdited[0].fullName = val
      }
      else {
        state.fullName = val
      }
    },
    SET_NEW_ADDRESS_VALUE(state, val) {
      state.isNewAddressComp = val
    },
    ADD_NEW_DETAILS(state, val) {
      state.addresses = val
    },
    UPDATE_SELECTED_ADDRESS(state) {
      state.addressToBeEdited = ''
    },
    SET_SELECT_VAL(state, val) {
      state.select = val
    },
    SET_SELECTED_ADDRESS(state, data) {
      state.addresses = data;
    },  
    SET_PICKUP_ADDRESS(state, val){
      state.pickedUpAddress = val
    }
  },
  actions: {
    getAddedAddresses: async ({ commit }) => {
      const result = await feathersClient.service('/api/v1/deliveryaddress').find({
        query: {
          showAddress: true
        }
      })
      commit('SET_ADDRESSES', result.data)
    },
    editAddress: async ({ state, dispatch }) => {
      const res = await feathersClient.service('/api/v1/deliveryaddress').patch(state.id, {
        fullName: state.addressToBeEdited[0].fullName,
        phone: state.addressToBeEdited[0].phone,
        addressLine1: state.addressToBeEdited[0].addressLine1,
        addressLine2: state.addressToBeEdited[0].addressLine2,
        city: state.addressToBeEdited[0].city,
        state: state.addressToBeEdited[0].state,
        zipCode: state.addressToBeEdited[0].zipCode,
        country: state.addressToBeEdited[0].country,
      }, {})
      if(res.isDefault == true){
        dispatch("patchAsDefaultAddress", res.id)
      }
    },
    addNewDetails: async ({ state, commit }) => {
      await feathersClient.service('/api/v1/deliveryaddress').create({
        fullName: state.fullName,
        phone: state.phone,
        addressLine1: state.addressLine1,
        addressLine2: state.addressLine2,
        city: state.city,
        state: state.state1,
        zipCode: state.pinCode,
        country: state.country,
      })
      const result = await feathersClient.service('/api/v1/deliveryaddress').find({
        query: {
          showAddress: true
        }
      })
      commit('ADD_NEW_DETAILS', result.data)
      commit('UPDATE_SELECTED_ADDRESS')
      commit('SET_NEW_ADDRESS_VALUE', false)
    },
    deleteAddress: async ({ state, commit, dispatch }, payload) => {
      const arr = state.addresses.filter(add => add.id == payload);
      let res = '';
      if(arr[0].isDefault == true){
        res = await feathersClient.service('/api/v1/deliveryaddress').patch(payload, {
          showAddress: false,
          isDefault: false
        },{})
      }
      else {
        res = await feathersClient.service('/api/v1/deliveryaddress').patch(payload, {
          showAddress: false
        },{})
      }
      
      const result = await feathersClient.service('/api/v1/deliveryaddress').find({
        query: {
          showAddress: true
        }
      })
      commit('SET_ADDRESSES', result.data)
      if(arr[0].isDefault == true){
        await dispatch("patchAsDefaultAddress", arr[0].id)
      }
      
    },
    patchAsDefaultAddress: async({ state, commit}, payload) => {
      const result = await feathersClient.service('/api/v1/deliveryaddress').find({
        query: {
           isDefault: true,
           showAddress: true
        }
      },{})
      if(result.data.length>0){
        await feathersClient.service('/api/v1/deliveryaddress').patch(result.data[0].id, {
          isDefault: false
        }, {})
      }
        await feathersClient.service('/api/v1/deliveryaddress').patch(payload, {
          isDefault: true
        },{})
      const result2 = await feathersClient.service('/api/v1/deliveryaddress').find({
        query: {
          showAddress: true
        }
      })
      commit('SET_ADDRESSES', result2.data)
    }, 
    patchAddressOnSelect: async({ state, commit} ,payload) => {
      const res= await feathersClient.service('/api/v1/deliveryaddress').find ({
        query: {
          id: payload.id
        }
      })
      commit('SET_PICKUP_ADDRESS', res.data)
  },
}
}
