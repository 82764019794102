import feathersClient from '../../../feathers-client'
export default {
  namespaced: true,
  state: {
    notification: '',
    unRead: null,


  },
  mutations: {
   SET_NOTIFICATION(state, val){
    console.log("setnotification value"+JSON.stringify(val))
	state.notification = val
   },
   SET_UNREAD(state, val){    
    state.unRead = val
   }
},
  actions: {
   async getNotification({state,commit}){
	
	const result = await feathersClient.service('/api/v1/notifications').find({
		query: {
			$total: true,
      $sort: {createdAt: -1}
		  }
	})

	commit('SET_NOTIFICATION',result)
   },
   async setRead({state,commit},payload){
    for (let index = 0;index < payload.Ids.length;index++){
    await feathersClient.service('/api/v1/notifications').patch(payload.Ids[index],{
      Read: payload.read
    })
    }
   },
   async unReadCount({state,commit}){
    const res = await feathersClient.service('/api/v1/notifications').find({
      query:{
        Read:false,
  			$total: true,
      }
    })
	  commit('SET_UNREAD',res.length)

   }
  },
}
